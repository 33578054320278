@charset "UTF-8";


.btn.focus, .btn:focus, a, :focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0,123,255,0);
}

.bg--red {
	background:#e91e63!important;
}

.bg--color--lighter {
	background:#f7f7f7;
}

.bg-dark--grey {
	background-image: linear-gradient(195deg, #adb5bd 0%, #212529 100%);
	box-shadow: 0 3px 3px 0 rgba(173, 181, 189, 0.15), 0 3px 1px -2px rgba(33, 37, 41, 0.2), 0 1px 5px 0 rgba(173, 181, 189, 0.15);
}

.bg-dark--grey:hover {
	color:#fff;
	opacity:0.9;
}

.hover--darkgrey:hover {
	color:#212529;
}

hr.horizontal.dark { 
    background-color: #bfc1cd;
}

hr.vertical.dark {
    background-color: #bfc1cd;
}

.ml-15 { margin-left:15px!important; }

.mr-15 { margin-right:15px!important; }

.text--white   {
	color:#fff;
}

.login__social__icons {
	max-width: fit-content;
	margin: 0 auto;
	padding: 0 15px;
}

.login__social__icons .btn {
	margin: 0;
	padding: 6px 10px;
}

.language__header {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	padding: 20px 0;
	width: 100%;
	text-align: right;
}

.language__select__option .dropdown-menu {
	position: absolute;
}

.language__select__option .navbar-nav {
	display: inline-table;
}

.site__logo {
	max-width: 160px;
	float: left;
	width: 100%;
}

.site__logo a img {
	max-width: 160px;
}

.login__top__header {
	width: 100%;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	min-height: 60px;
	padding-bottom: 33px;
	padding-right: 16px;
	padding-left: 16px;
}

.login__form .input-group.input-group-outline.is-focused .form-label + .form-control, .login__form .input-group.input-group-outline.is-filled .form-label + .form-control {
	border-color: #212529 !important;
	box-shadow: inset 1px 0 #212529, inset -1px 0 #212529, inset 0 -1px #212529;
	border-top-color: transparent !important;
}

.login__form .input-group.input-group-outline.is-focused .form-label::before, .login__form .input-group.input-group-outline.is-focused .form-label::after, .login__form .input-group.input-group-outline.is-filled .form-label::before, .login__form .input-group.input-group-outline.is-filled .form-label::after {
	border-top-color: #212529;
	box-shadow: inset 0 1px #212529;
}

.login__form .input-group.input-group-outline.is-focused .form-label::before, .login__form .input-group.input-group-outline.is-focused .form-label::after, .login__form .input-group.input-group-outline.is-filled .form-label::before, .login__form .input-group.input-group-outline.is-filled .form-label::after {
	border-top-color: #212529;
	box-shadow: inset 0 1px #212529;
}

.login__form .input-group.input-group-outline.is-focused .form-label, .input-group.input-group-outline.is-filled .form-label {
	color: #212529;
}

.social__icons .fa.fa-linkedin {
	font-size: 1.325rem !important;
}

.sidenav.side__main__nav .navbar-brand {
	padding: 1.5rem 2.4rem;
}

.side__main__nav .sidenav-header {
	height:4.175rem;
}

.panel__logo img {
	height: auto;
	max-height: none !important;
	max-width: 105px !important;
}

 .sidenav.navbar:hover .panel__logo .Logo__name {
	display: inline-block;
	
}

.sidenav.navbar:hover .navbar-brand.panel__logo img.Logo__icon {
	top:-3px;
}

.navbar-vertical .navbar-brand.panel__logo img.Logo__icon {
	width: 17px !important;
	min-width: 17px !important;
	position: relative;
	top: -3px;
	margin-right: 0;
}

.g-sidenav-hidden .navbar-vertical .navbar-brand.panel__logo img.Logo__icon {
	top:-1px;
}


.g-sidenav-hidden .panel__logo .Logo__name {
	display: none;
}

.side__main__nav .nav {
	width: 100%;
}

.side__main__nav .navbar-nav .material-icons, .side__main__nav .navbar-nav .material-symbols-rounded {
	font-size: 24px;
	margin-left: 3px;
	display: flex;
	max-width: 24px;
	min-width: 24px;
	align-content: center; 
	align-items: center;
	justify-content: center;
	direction: unset;
}

.side__main__nav .navbar-nav .material-icons img {
	/* max-width: 100%; */
	max-width: 20px;
}

.side__main__nav .navbar-nav .material-icons.max-w-19 img {
	/* max-width: 100%; */
	max-width: 19px;
}

.side__main__nav .navbar-nav .material-icons.max-w-21 img {
	max-width: 21px;
}

.side__main__nav .navbar-nav .material-icons.max-w-24 img {
	max-width: 24px;
}

.side__main__nav.sidenav[data-color="dark"] .navbar-nav > .nav-item .nav-link.active + .collapse .nav-item .nav-link.active, .side__main__nav.sidenav[data-color="dark"] .navbar-nav > .nav-item .nav-link.active + .collapsing .nav-item .nav-link.active, .side__main__nav.sidenav[data-color="dark"] .navbar-nav .nav .nav-item .nav-link.active, .navbar-vertical .navbar-nav > .nav-item .nav-link.active{
	background-image: linear-gradient(195deg, #344767 0%, #344767 100%);
}



.navbar-vertical.side__main__nav .navbar-nav .nav-link .avatar {
	background: #03a9f4;
	min-width: 1.875rem;
	font-size: 0.875rem;
	font-weight: 300;
}

.right__nav__bar .nav__bar__inner {
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	background-clip: border-box;
	background-color:#fff!important;
	border: 0 solid rgba(0, 0, 0, 0.125);
	border-radius: 0.45rem;
	width: 100%;
	padding: 20px 20px;
}

.nav__panel__right {
	padding-left: 25px;
}

.nav__panel__right li {
	margin: 0 17px;
}

.nav__panel__right li a {
	color: #7b809a;
	font-weight: normal;
}

.nav__panel__right li a:hover, .nav__panel__right li a.active, .nav__panel__right li.active a {
	/* color: #344767; */
	color: #e91e63;
}

.cross__icon {
	position: absolute;
	right: 20px;
	top: 11px;
}

.font-size-15 {
	font-size: 15px;
}

.font-size-16 {
	font-size: 16px;
}

.cross__icon a i {
	/* font-size: 24px !important; */
	font-size: 29px !important;
}

/* .crossbig-icon a i {
	font-size: 29px !important;
} */

.edit__icon {
	padding-top: 16px;
}

.info__lisiting .list-group-item {
	padding-bottom: 0rem;
}

.pl-0 { padding-left:0!important; }


.pr-0 { padding-right:0!important; }

.tags__block .choices__inner {
	flex-wrap: wrap;
}

.tags__block .choices__inner .choices__input {
	width: 100%!important;
	max-width: 450px!important;
} 

.tags__block .choices__list--dropdown {
	top: auto;
	bottom: -20px;
}

/* .notes__input.input-group.input-group-dynamic .form-control, .notes__input.input-group.input-group-dynamic .form-control:focus, .notes__input.input-group.input-group-static .form-control, .notes__input.input-group.input-group-static .form-control:focus {
	background-image: linear-gradient(0deg, #212529 2px, rgba(33, 37, 41, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
} */

.notes__input .form-control {
	padding-top: 0;
	height: 370px;
	line-height: 20px;
	/* max-height: 370px;
	resize: none; */
	font-size: 0.875rem !important;
	color:#7b809a;
	font-weight: 400;
}


.margin-top-3 {
	margin-top: 3px;
}

.text-color-light {
	color: #6c757d !important;
}

.text-body-color {
	color:#7b809a;
}

.tags__block .choices__inner {
	padding-top: 5px;
}

.group__list .form-label {
	width: 100%;
}

/* .multisteps-form__panel .input-group.input-group-dynamic .form-control, .multisteps-form__panel .input-group.input-group-dynamic .form-control:focus, .multisteps-form__panel .input-group.input-group-static .form-control, .multisteps-form__panel .input-group.input-group-static .form-control:focus {
	background-image: linear-gradient(0deg, #212529 2px, rgba(33, 37, 41, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
}

.multisteps-form__panel .input-group.input-group-dynamic.is-focused label, .multisteps-form__panel .input-group.input-group-static.is-focused label {
	color: #212529;
}

.multisteps-form__panel .choices .choices__list.choices__list--single, .multisteps-form__panel .choices .choices__list.choices__list--single:focus {
	background-image: linear-gradient(0deg, #212529 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
} */

.nav.nav-pills.nav--tabs .nav-link.active {
	background-color: #fff;
}

.card--min--height {
	min-height: 590px;
}

.group__list a.form-label {
	width: 100%;
	float: left;
	padding-bottom: 5px;
}

.group__list a.form-label:hover, .group__list a.active {
	color:#212529;
}

.groups__active a.active {
	color:#212529;
	font-weight: bold;
}

.right__panel__scroll {
	overflow: auto;
}

.scroll__bar__height {
	position: relative;
	height: auto ;
	min-height:100%;
	max-height: 75vh;
	overflow: auto;
}

.ps__rail-x {
	display: none !important;
}

.group__list  label, .group__list .form-label {
	margin-left: 0;
}

/* #dropzone .dz-error-mark {
	display: none;
} */

.view__details__area  {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

.view__card__lisiting .list-group-item {
	padding: 0 0 3px;
}

.hover--dark:hover {
	color: #344767!important;
}

.nav.nav-pills.tab--view--select {
	flex-direction: row !important;
}

/* .nav.nav-pills.tab--view--select .moving-tab.nav-link {
	display: none;
} */

.dropzone .fallback input[type="file"] {
	opacity: 0;
}

.label__action .dropstart {
	position: relative;
	top: -5px;
}

.card-icons {
	display: flex;
	align-content: center;
	align-items: center;
}

.mr-5 {
	margin-right: 5px;
}

.navbar__search__left {
	position: relative;
	top: -1px;
}

.avatar h4 {
	color: #e91e63;
}

.nav.nav-pills .moving-tab .nav-link {
	color: transparent!important;
}

.fileuplaod__block .dropzone .dz-preview .hidetext, .fileuplaod__block .dz-error-mark.hidediv{
	display: none !important;
}

.remove__download__icons {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

.remove__download__icons a, .remove__download__icons a span {
	cursor: pointer;
	color: var(--bs-body-color);
}

.remove__download__icons a:hover, .remove__download__icons a:hover span, .remove__download__icons a:focus {
	color:#e91e63;
}

.fileuplaod__block .dropzone.dz-clickable * {
	cursor: inherit;
}

.flatpickr-rContainer {
	display: none;
}

.flatpickr-month {
	padding-top: 4px;
}

.candidate__list .form-check {
	padding-left: 0;
}

.view__details__area .hover--dark:hover  {
	color: #e91e63 !important;
}

.card-icons a {
	display: flex;
}

.choices .choices__list.choices__list--single .choices__item--selectable {
	color: #495057;
	opacity: 1;
}

.form-control {
	/* color: #495057; */
	color: #7b809a;
}

.multisteps-form__form {
	height: auto !important;
	/* min-height: 520px; */
	min-height: 400px;
}

.multisteps-form__panel {
	position: relative;
	height: 0 !important;
	display: none;
}

.multisteps-form__panel.js-active, .multisteps-form__panel.active {
	height: auto !important;
	opacity: 1;
	visibility: visible;
	display: block;
}

.filter-search-inner {
	min-width: 160px;
}

.navbar__search__left .btn {
	margin: 0 0 0 10px !important;
	border-radius: 20px;
	min-width: 100px;
}

.card-detail-head p {
	font-size: 13px !important;
	margin: 0 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month, .flatpickr-current-month input.cur-year {
	font-size: 17px;
}	

.add-icon {
	position: relative;
	/* top: -2px; */
	top:0;
}

.minheight-460  {
    min-height: 460px;
}

.profile__img__in {
	min-height: 270px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.multisteps-form__content .dropzone .dz-message {
	color: #7b809a;
	font-size: inherit;
	max-width: 320px;
	margin: 3em auto;
	line-height: 1.4rem;
}

.input--cross-icon {
	position: absolute;
	right: 25px;
	top: 15px;
}

.view__details__area .avatar {
	min-width: 75px;
}

.has-clear.input-group-outline {
	width: 100%;
	position: relative;
}

.has-clear .form-control {
	background: none;
	border: 1px solid #d2d6da;
	border-radius: 0.375rem;
	border-top-left-radius: 0.375rem !important;
	border-bottom-left-radius: 0.375rem !important;
	padding:0.625rem 1.95rem 0.625rem 0.75rem !important;
	line-height: 1.3 !important;
}

.has-clear.input-group-outline::before {
	content: "";
	width: 100.4%;
	height: 100.4%;
	position: absolute;
	left: -1px;
	top: -1px;
	border-radius: 6px;
	border: 2px solid #e91e63;
	opacity:0;
	 transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}


.has-clear.input-group-outline.is-focused::before {
	opacity:1;
	 transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}

.has-clear.input-group-outline.is-focused .form-control {
	border: 1px solid transparent;
}


.has-clear .form-control-clear {
	top: 11px;
	right: 12px;
}

.has-clear .form-control-clear.hidden {
	opacity:0;
}

.has-clear .form-control-clear {
	opacity:1;
}

.sorting__tags .tags__block .choices__inner .choices__input {
	display: none;
}

.sorting__tags {
	flex-wrap: wrap;
} 

.sort__clear__btn {
	border-radius: 50px;
	padding: 0.325rem 1.8rem;
	margin-bottom: 4px;
}

.candidate__img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	margin: 0 15px 0 0;
}

.candidate__img img {
	width: 100%;
}

.lisiting__table__new table.table thead tr th:first-child, .lisiting__table__new table.table tbody tr td:first-child {
	width: 90px;
	text-align: center;
}

.lisiting__table__new table.table tbody tr td .form-check {
	padding-left: 0;
}

.lisiting__table__new table.table thead tr th:nth-child(2), .lisiting__table__new table.table tbody tr td:nth-child(2) {
	width: 80px;
	text-align: center;
}

.lisiting__table__new table.table tbody tr td .candidate__img {
	margin: 0 auto;
}

.lisiting__table__new table.table thead th {
	padding: 0.75rem 0.75rem;
}

.lisiting__table__new table.table tbody tr td {
	padding-left:0.75rem !important;
	padding-right:0.75rem !important;
	position:relative;
}

.lisiting__table__new table.table tbody tr td span.text-secondary {
	line-height: 0.9;
}

.action__btns {
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	padding-top: 8px;
}

.actn__btn__in {
	margin: 0 4px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	position: relative;
	top: -3px;
}

.step__choose .btn:hover, .step__choose .btn:focus {
	background: #e91e63;
	color: #fff;
	opacity: 1;
}

.actn__btn__in .material-icons:hover, .dropstart .material-icons:hover, .dropdown #navbarDropdownMenuLink i.fa:hover, .dropdown:hover i.fa, .cross__icon .nav-link:hover, .language__select__option .nav-link:hover {
	color: #e91e63!important;
}

.edit__icon:hover i, .edit--icon:hover i {
	color: #e91e63!important;
}

.lisiting__table__new table.table tbody tr td .candidate__img h5 {
	line-height: 49px;
	color: #e91e63;
	font-size: 16px;
}

.navpanel__select__end__action {
	padding-right: 40px;
	position: relative;
}

.navpanel__select__end__action .dropstart {
	position: absolute;
	right: 0;
	top: 8px;
}

.navpanel__select__end__action .dropstart .material-icons {
	-webkit-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    transform:rotate(90deg);
}

.navpanel__select__end__action .dropstart i.material-icons {
	font-size: 29px !important;
	position: relative;
	top: -3px;
}


/* .navpanel__select__end__action .dropstart .dropdown-menu, .dropstart.showin-mobile .dropdown-menu {
	display: block !important;
	margin: 0 !important;
	right: 0 !important;
	top: 31px;
	transform: scale(0, 0);
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
}

.navpanel__select__end__action .dropstart .dropdown-menu.show, .dropstart.showin-mobile .dropdown-menu.show {
	margin: 0 !important;
	transform: scale(1, 1);
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
} */

.navpanel__select__end__action .dropstart .dropdown-menu[data-bs-popper], .dropstart.showin-mobile .dropdown-menu[data-bs-popper] {
	margin-right: -23px;
	margin-top: 30px;
}

.showin-mobile {
	display: none;
}

.login__form .input-group {
	position: relative;
}

.invalid-feedback {
	width: 100%;
	padding: 4px 10px;
	font-size: 13px;
	font-weight: 600;
	color: #fff;
	margin: 7px 0 0 !important;
	z-index: 12;
	border-radius:0.375rem;
}

/* .login__form .invalid-feedback {
	width: fit-content;
	position: absolute;
	top: -34px;
	padding: 4px 10px;
	font-size: 13px;
	font-weight: 400;
	color: #fff;
	margin: 0 auto !important;
	left: 18%;
	z-index: 12;
	text-align: center !important;
	border-radius: 0.375rem;
	background: #1a1a1a; 
	background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%);
} */

/* .login__form .invalid-feedback::after {
	content: "";
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 10px 0 10px;
	border-color: #D81B60 transparent transparent transparent;
	position: absolute;
	bottom:-8px;
	left:44%;
} */

.login__form .was-validated .form-check-input:valid:checked, .login__form .form-check-input.is-valid:checked {
	border-color: #42424a!important;
	background-color: #42424a!important;
}

.login__form .was-validated .form-check-input:valid ~ .form-check-label, .login__form .form-check-input.is-valid ~ .form-check-label {
	color: #7b809a!important;
}

.login__form .was-validated .form-check-input:valid:focus, .login__form .form-check-input.is-valid:focus {
	box-shadow: 0 0 0 0px rgba(102, 212, 50, 0);
}

.limport__upload__block .remove__download__icons {
	justify-content: center;
}

.limport__upload__block .remove__download__icons .downloadicon-dropzone {
	display: none;
}



.navbar-vertical.navbar-expand-xs .navbar-collapse {
	overflow: initial;
}

.right-close-icon {
	position: absolute;
	top: 0;
	right: 10px;
}

hr.horizontal.light {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0));
	background-color: transparent;
}

.forgot__password a {
    color: var(--bs-body-color);
}

.forgot__password a:hover {
	color: #e91e63;
}

.action__btns__new {
	padding: 0;
	display: inline-block;
	text-align: right;
} 

.action__btns__new .dropstart {
	position: relative;
	right: 0;
	top: 5px;
	display: inline-block;
}

.navselect__new { 
	padding-right: 0;
}
 


.dropstart.rortate--none .material-icons { 
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}
 
.rename__option .form-check {
	position: relative;
	/* display: flex;
	flex-wrap: wrap;
	justify-content: flex-start; */
	text-align: left;
	width: 100%; 
	padding-right: 30px;
	/* display: inline-flex; */
    margin-bottom: 10px;
	/* align-items: flex-start; */
}

.rename__option .dropdown {
	position: absolute;
	right:15px;
	top: 0px;
}

.rename__option .form-check .dropdown .dropdown-menu, .rename__option .form-check .dropdown-menu.show {
	margin: 0 0 0 !important;
	right: 1px !important;
	left: auto !important;
	inset: 25px 0 auto auto !important;
	/* top: 23px !important; */
	/* min-height: 47px; */
}


.reset__password__form .input-group.input-group-dynamic.is-focused label, .reset__password__form .input-group.input-group-static.is-focused label {
	color: #212529;
} 

.reset__password__form .input-group.input-group-dynamic .form-control, .reset__password__form .input-group.input-group-dynamic .form-control:focus, .reset__password__form .input-group.input-group-static .form-control, .reset__password__form .input-group.input-group-static .form-control:focus {
	background-image: linear-gradient(0deg, #212529 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #212529 1px, rgba(209, 209, 209, 0) 0);
	border-radius: 0 !important;
}

.bg-musterd {
	background: #fb8c00; 
}


.group__search .input-group.input-group-outline .form-control { 
	background: none;
	border: 1px solid #d2d6da;
	border-radius: 0.375rem !important;
	padding: 0.625rem 1.95rem 0.625rem 0.75rem !important;
	line-height: 1.3 !important; 
}

.group__search .input-group-outline.is-focused::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute; 
	left: 0px;
	top: 0px;
	border-radius: 0.375rem;
	border: 2px solid #e91e63; 
}

.group__search .input-group-outline.is-focused .form-control {
	border: 1px solid transparent;
}

.group__search .add__btn {
	background: transparent;
	border: none;
	position: absolute;
	right: 8px;
	top: 0;
	color: var(--bs-body-color); 
	padding: 0;
	height: 41px;
	line-height: 49px;
	opacity:0;
	cursor: pointer!important;
	z-index:9;
}

.group__search .add__btn:hover {
	color:#e91e63;
}

.group__search .is-focused .add__btn {
	opacity:1;
}

.ml-1 {
	margin-left: 5px !important;
}

.rename__option .avatar {
    margin: 0 0 0 5px;
    display: inline-block;
    position: relative;
    top: 0;
    float: left;
}

.rename__option .form-check .custom-control-label {
    position: relative;
    /* top: 3.6px; */
    cursor: pointer;
    width: 100%;
    max-width: 185px; 
    margin-left: .4rem;
    margin-bottom: 8px;
    line-height: 1.3;
}

.rename__option .form-check:not(.form-switch) .form-check-input {
	margin: 3px 0 0 !important; 
	/* margin: 5px 0 0 !important; */
	 float: left!important;
}

.rename__option .avatar h4 {
	font-size: 11px;
	text-align: center;
	line-height: 24px !important;
	font-weight: 900;
}

.new__header__bar .sidenav-toggler-inner {
	top: -1px;
	position: relative;
	cursor: pointer;
	z-index: 9;
}

.disabled .form-check-input:disabled {
	opacity: 1;
}

.form-check.disabled:not(.form-switch) .form-check-input[type="checkbox"]:checked {
	background: #a5a5a5;
	border-color: #a5a5a5;
}



/*********************************************
	Select option Design Start
***********************************************/

.custom-select, .custom--select--search {
	position: relative;
}

.custom-select select, .custom--select--search select {
	display: none;
}

.custom-select .select-selected, .custom--select--search .current {
	color: #495057;
	opacity: 1;
	padding-bottom: 0.6rem;
	cursor: pointer;
	font-size: 14px;
	width: 100%;
	float: left;
}


.custom-select .select-selected, .custom--select--search .current {
	background: no-repeat bottom, 50% calc(100% - 1px);
	background-size: 0 100%, 100% 100%;
	position: relative;
	background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}

.custom-select .select-selected.select-arrow-active, .custom--select--search .open .current{
	 background-size:100% 100%, 100% 100%;
}	

.custom-select .select-selected::after, .custom--select--search .current::after {
	content: "\f107";
	font: normal normal normal 14px/1 FontAwesome;
	position: absolute;
	right: 13.5px;
	top: 8px;
	color: var(--bs-body-color);
	/* pointer-events: none; */
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}

.custom-select .select-selected.select-arrow-active::after, .custom--select--search .open .current::after {
	 -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	
}



.custom-select .select-items.select-hide, .custom--select--search.select-items.select-hide {
	background: #fff;
}

.custom--select--search .list::before {
	font-family: "FontAwesome";
	content: "\f0d8";
	position: absolute;
	top: -10px;
	left: 28px; 
	right: auto;
	font-size: 22px;
	color: #fff;
	transition: top 0.35s ease;
}

.custom-select .select-items::before {
	font-family: "FontAwesome";
	content: "\f0d8";
	position: absolute;
	top: -20px;
	left: 28px; 
	right: auto;
	font-size: 22px;
	color: #fff;
	transition: top 0.35s ease;
}

.custom-select .select-items.select-hide::before { 
	top: 0px;
}

.custom--select--search .open .list::before { 
	top: -20px;
}

.custom-select .select-items.select-hide, .custom--select--search .list {
	opacity: 0;
	
	-moz-transform: scale(0);
	-moz-transform-origin: 0 0;
	-o-transform: scale(0);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(0);
	-webkit-transform-origin: 0 0;
	transform: scale(0); 
	transform-origin: 0 0;
	
	
}


.custom-select .select-items, .custom--select--search .list {
	position: absolute;
	width: 100%;
	left: 0;
	top: 40px;
	background: no-repeat bottom, 50% calc(100% - 1px);
	background-size: 0 100%, 100% 100%;
	transition: 0.2s ease;
	background-color: #fff;
	z-index: 9;
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1); 
	transform: scale(1);
}

.custom-select .select-items, .custom--select--search .open .list {
	cursor:pointer;
	display: block;
	opacity: 1;
	transform-origin: 0 0;
	/* pointer-events: none; */
	-webkit-backface-visibility: hidden;
	backface-visibility: visible;
	will-change: 0 0;
	border: none;
	border-radius: 0.5rem;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	/* transform: scale(0.95); */
	padding: 1rem 0.5rem;
	transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.custom--select--search .list ul {
	padding: 0;
	margin: 0;
}

.custom-select .select-items div, .custom--select--search .list ul li {
	list-style: none;
	position: relative;
	padding: 0.3rem 1rem;
	font-size: 0.895rem;
	border-radius: 0.375rem;
	opacity: 1;
	cursor: pointer;
	color: #7b809a;
	/* pointer-events: auto; */
	transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-select .select-items div:hover, .custom--select--search .list ul li:hover {
	background-color: #f2f2f2;
}


.custom--select--search .dd-search .dd-searchbox {
	background: no-repeat bottom, 50% calc(100% - 1px);
	background-size: 0 100%, 100% 100%;
	position: relative;
	background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
}

.dd-search {
	position: relative;
}

.custom--select--search .dd-search.is-focused .dd-searchbox, .custom--select--search .open .dd-searchbox {
	 background-size:100% 100%, 100% 100%;
}	

.custom--select--search .dd-search i {
	position: absolute;
	width: 1px;
	height: 48%;
	background-color: #000;
	left: 2px;
	top: 21%;
	animation-name: blink;
	animation-duration: 800ms;
	animation-iteration-count: infinite;
	opacity: 1;
}

.custom--select--search .dd-search.is-focused i {
	display: none;
}

@keyframes blink {
	from { opacity: 1; }
	to { opacity: 0; }
}




/*********************************************
	Select option Design End
***********************************************/



/*********************************************
	Select option Design Start new
***********************************************/



.custom-select {
  position: relative;
}
.select-css {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  z-index: 10;
}
.select-css::-ms-expand {
  display: none;
}

.custom-select-icons::after {
	content: "\f107";
	font: normal normal normal 14px/1 FontAwesome;
	position: absolute;
	right: 13.5px;
	top: 8px;
	color: var(--bs-body-color);
	/* pointer-events: none; */
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}

.tags__searching__list__inner .is-focused .custom-select-icons::after {
	 -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	
}

/* .custom-select-icons {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 20;
  border: 1px solid white;
  background: transparent;
} */
.custom-select-options {
  border: 1px solid #aaa;
  border-radius: 0 0 0.25em 0.25em;
  line-height: 1.5;
  margin: 0;
  margin-top: -0.5em;
  padding: 0;
  list-style-type: none;
  font-weight: normal;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  width: calc(100% - 1px);
  background-color: #ffffff;
}
.custom-select-options li {
  padding: 1em;
}
.custom-select-options li:hover {
  background: blue;
  color: #fff;
  border: 1px solid blue;
  border-width: 0 0 0 1px;
}

.custom-select-options li:focus {
  border: 1px solid blue;
}









/*********************************************
	Mutisteps Design Start
***********************************************/

.mutiselect__tabs {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	border: none;
}

.mutiselect__tabs li {
	transition-property: all;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	transition-delay: 0s;
	position: relative;
	padding-top: 0;
	color:#f48aaa;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.75rem;
	border: none;
	background-color: transparent;
	outline: none !important;
	cursor: pointer;
	text-indent: 0;
	background-color: transparent!important;
	border-color: transparent!important;
	border: none!important;
}

.mutiselect__tabs li a::after {
	position: absolute;
	top: 7px;
	left: calc(-50% - 13px / 2);
	transition-property: all;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	transition-delay: 0s;
	display: block;
	width: 100%;
	height: 2.2px;
	content: '';
	background-color: #f48aaa;
	z-index: 1;
}

.mutiselect__tabs li:first-child a::after {
	display: none;
}

.mutiselect__tabs li a::before {
	position: absolute;
	top: 2px;
	left: 49.7%;
	display: block;
	width: 13px;
	height: 13px;
	content: '';
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	/* transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s; */
	/* transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s; */
	transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
	border: 2px solid rgba(255, 255, 255, 0);
	border-radius: 50%;
	background-color:#f48aaa;
	box-sizing: border-box;
	z-index: 3;
}

.mutiselect__tabs li a::before {
	-webkit-transform: translateX(-50%) scale(1);
	transform: translateX(-50%) scale(1);
	background-color: #f48aaa;
}

.mutiselect__tabs li a.active::before {
	-webkit-transform: translateX(-50%) scale(1.2);
	transform: translateX(-50%) scale(1.2);
	background-color: currentColor;
}

.mutiselect__tabs li a {
	padding: 22px 0 0;
	text-align: center;
	color: #f48aaa;
	background-color: transparent!important;
	border-color: transparent!important;
	border: none!important;
}

.mutiselect__tabs .nav-link.active, .mutiselect__tabs .nav-item.show .nav-link, .mutiselect__tabs .nav-link:hover, .mutiselect__tabs .nav-link:focus {
	color: #f48aaa;
	background-color: transparent!important;
	border-color: transparent!important;
	border: none!important;
}

.mutiselect__tabs li a.active::before, .mutiselect__tabs li a.active::after {
	background-color: #fff;
}

.mutiselect__tabs li.show a::before, .mutiselect__tabs li.show a::after {
	background-color: #fff;
}


.mutiselect__tabs .nav-link:hover, .mutiselect__tabs .nav-link:focus {
	isolation: auto;
}




/*********************************************
	Mutisteps Design End
***********************************************/

.min-height-450 {
	min-height: 450px;
}

.password__lisiting {
	padding: 2px 0 0 18px;
}

.password__lisiting li {
	font-weight: 300 !important;
	font-style: italic;
}

.filter-search-inner .form-control {
	padding-top: 0;
}

.tags__searching__list .form-control {
	 -webkit-appearance: none;
   -moz-appearance: none;
        appearance: none;
}

.tags__searching__list datalist::before {
	font-family: "FontAwesome";
	content: "\f0d8";
	position: absolute;
	top: -20px;
	left: 28px;
	right: auto;
	font-size: 22px;
	color: #fff;
	transition: top 0.35s ease;
}

.tags__searching__list datalist {
	position: absolute;
	width: 100%;
	left: 0;
	top: 40px;
	transition: 0.2s ease;
	background-color: #fff;
	z-index: 9;
	cursor:pointer;
	border: none;
	border-radius: 0.5rem;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	padding: 1rem 5rem;
}

.tags__searching__list datalist option {
	position: relative;
	padding: 0.3rem 1rem;
	font-size: 0.895rem;
	border-radius: 0.375rem;
	opacity: 0.5;
	cursor: pointer;
	pointer-events: auto;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.tags__searching__list datalist option:hover {
	background-color: #f2f2f2;
}

.input__filter__tags form {
	width: 100%;
}

.input__filter__tags .form-clear {
	right: 10px;
	top: 8px;
	font-size: 13px;
	cursor: pointer;
	z-index: 9;
}

.input__filter__tags .form-clear.hidden {
	opacity: 0;
}

.input__filter__tags .form-clear {
	opacity: 1;
}

.input__filter__tags .form-clear:hover {
	color: #e91e63;
}

.input__filter__tags .filters--listing {
	opacity: 0;
	-moz-transform: scale(0);
	-moz-transform-origin: 0 0;
	-o-transform: scale(0);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(0);
	-webkit-transform-origin: 0 0;
	transform: scale(0);
	transform-origin: 0 0; 
}


.input__filter__tags .show .filters--listing {
	opacity: 1;
	-moz-transform: scale(1);
	-moz-transform-origin: 0 0;
	-o-transform: scale(1);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(1);
	-webkit-transform-origin: 0 0;
	transform: scale(1);
	transform-origin: 0 0;
}

.filters--listing {
	cursor: pointer;
	display: block;
	opacity: 1;
	transform-origin: 0 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: visible;
	will-change: 0 0;
	border: none;
	border-radius: 0.5rem !important;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	padding: 1rem 0.5rem;
	transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	background: #fff;
	position: absolute;
	z-index: 9;
	top: 40px;
	width: 100%;
	margin: 0 !important;
}

.filters--listing::before {
	font-family: "FontAwesome";
	content: "\f0d8";
	position: absolute;
	top: -20px;
	left: 28px;
	right: auto;
	font-size: 22px;
	color: #fff;
	transition: top 0.35s ease;
}

.filters--listing li {
	list-style: none;
	position: relative;
	padding: 0.3rem 1rem;
	font-size: 0.895rem;
	border-radius: 0.375rem;
	opacity: 0.5;
	cursor: pointer;
	/* pointer-events: auto; */
	transition: background-color 0.3s ease, color 0.3s ease;
}

.filters--listing li a {
	font-size: 0.895rem;
	/* color: #344767; */
	color: var(--bs-body-color);
}

.filters--listing li:hover, .filters--listing li a:hover {
	background-color: #f2f2f2;
}

.input__filter__tags .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #495057;
}

.input__filter__tags .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #495057;
}

.input__filter__tags .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #495057;
}

.input__filter__tags .form-control:-moz-placeholder { /* Firefox 18- */
  color: #495057;
}

.fileuplaod__block .form-control {
	min-height: 150px;
	background: white;
	padding: 20px 20px;
	position: relative;
}

.fileuplaod__block .fallback {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 123;
	cursor: pointer;
}

.fileuplaod__block .fallback input[type="file"] {
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

.fileuplaod__block .form-control p {
	text-align: center;
	margin: 3em 0;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5rem;
	color: #7b809a;
}

.file__detail p {
	width: 70%;
	float: left;
}

.file__action__btns {
	padding: 0;
	width: fit-content;
	align-content: center;
	align-items: center;
}

.file__detail {
	justify-content: space-between;
	display: flex;
}

.file--btn {
	background: transparent;
	padding: 0;
	border: none;
	margin: 0 0 0 7px;
	position: relative;
	top: 5px;
}

.file--btn .material-icons {
	color: #7b809a;
}

.file--btn:hover, .file--btn .material-icons:hover {
	color: #e91e63;
	text-decoration: none;
}

.navbar-main {
	min-height: 50px;
}

.manageteam__table .table td, .manageteam__table .table th {
	vertical-align: middle;
}

.table__action {
	width: 100%;
	text-align: center;
	position: relative;
}

.table__action .btn {
	padding: 0;
	margin: 0;
	width: 30px;
}

.manageteam__table .avatar h4 {
	font-size: 16px;
	text-align: center;
	line-height: 36px !important;
	font-weight: 900;
}

.tags__block {
	display: flex;
	align-items: center;
	vertical-align: top;
	padding: 0.5rem 0;
	min-height: 40px;
	flex-wrap: wrap;
}

.tags__choices__item {
	display: inline-block;
	vertical-align: middle;
	border-radius: 20px;
	padding: 4px 30px 4px 10px;
	font-size: 12px;
	font-weight: 500;
	margin-right: 3.75px;
	margin-bottom: 3.75px;
	background-color: #c5c7cd;
	border: 1px solid #c5c7cd;
	color: #ffffff;
	word-break: break-all;
	box-sizing: border-box;
	position: relative;
}

.tags__choices__item.active {
	background-color: #191919;
	border: 1px solid #191919;
}

.tags__choices__item .tag__choices__button {
	position: absolute;
	display: inline-block;
	margin: 0;
	border-color: rgba(255, 255, 255, 0.3);
	background: transparent;
	border-style: solid;
	opacity: 0.75;
	transition: all 0.2s ease-in-out;
	border-width: 0 0 0 1px;
	color: #fff;
	padding: 0 0 0 2px;
	top: 4px;
	right: 6px;
}

.tags__choices__item .tag__choices__button .material-icons {
	font-size: 13px;
	position: relative;
	top: 2.5px;
}

a[data-scroll].active {
	background: #f0f2f5;
	color: #344767;
}


.stepsform__inner .custom-select .select-selected {
	padding-top: 0.5rem;
}

.navbar.shadow-blur .nav__panel__right li a {
	color: #344767;
}

.navbar.shadow-blur .nav__panel__right li a:hover {
	color: #e91e63;
}

.navbar.shadow-blur a i.material-icons {
	color: #344767 !important;
}

.navbar.shadow-blur a i.material-icons:hover {
	color: #e91e63!important;
}

.input-group.input-group-outline.is-focused .form-label {
    z-index: 123;
}

.stepsform__inner .custom-select .select-items {
	top: 50px;
}

.stepsform__inner .custom-select .select-items {
	max-height: 300px;
	pointer-events: auto;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	will-change: scroll-position;
}

.stepsform__inner .custom-select .select-selected::after {
	top: 13px;
}

.settings__form .input-group .form-control {
	border-top-right-radius: 0.375rem !important;
	border-bottom-right-radius: 0.375rem !important;
}

.panel__icon {
	width: 22px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	position: relative;
	top: -1px;
}

.topfixed__bar.sticky {
	top: 70px !important;
}

.topfixed__bar.sticky.top-sticky {
	top: 15px !important;
}

.topfixed__bar ul li .nav-link {
	align-content: center;
	align-items: center;
}

.currenttext { 
	font-size: 0.875rem;
	font-weight: 400;
 }
 
 .passsword__input .input-group .form-control {
	border: none;
}
 
.passsword__input .input-group {
	border-radius: 0.375rem;
	border: 1px solid #d2d6da;
	padding-right: 35px;
}

.passsword__input {
	position: relative;
	width: 100%;
}

.passsword__input .show__icon {
	position: absolute;
	right: 12px;
	top: 0;
	width: 25px;
	height: 39px;
	text-align: center;
	line-height: 47px;
	cursor: pointer;
	z-index: 123;
}

.passsword__input .show__icon .material-icons {
	font-size: 23px;
}

.passsword__input .input-group.input-group-outline.is-focused .form-label + .form-control, .passsword__input .input-group.input-group-outline.is-filled .form-label + .form-control {
	border-color: transparent !important;
	border-top-color: transparent !important;
	box-shadow: inset 0px 0 #e91e63, inset 0px 0 #e91e63, inset 0 0px #e91e63; 
}

.passsword__input .input-group.input-group-outline.focused.is-focused, .passsword__input .input-group.input-group-outline.is-filled {
	border-color: #e91e63 !important;
	border-top-color: transparent !important;
	box-shadow: inset 1px 0 #e91e63, inset -1px 0 #e91e63, inset 0 -1px #e91e63;
}


.or__text { 
	width: 100% !important;
	float: left;
}

.icon__checkbox {
	color: #344767;
	position: relative;
	top: 5.5px;
}

.candidate__list .dropdown .btn.btn-link {
	padding: 0 11px !important;
	position: relative;
	right: -10px;
}

.group__search {
	padding-right: 17px;
}


.right__fixed__area__block .nav-tabs {
	border-bottom: 0px solid #dee2e6;
}

.right__fixed__area__block .slider__panels, .scroll__bar__height .slider__panels {
	padding-right: 17px;
}

.login__form .input-group.input-group-outline.is-focused .form-label, .input-group.input-group-outline.is-filled .form-label {
	z-index: 99;
	height: 18px;
}

.navbar.shadow-blur .fullwidth__stack li a:hover i.material-icons {
	color: #344767 !important;
}
 
.stepsform__inner .input-group.is-filled .form-control {
    box-shadow: 0 0 0 0px #fff inset;
} 

.navbar-vertical .nav-item .nav-link {
	color: #fff !important;
}



/* .navbar-collapse .sub__nav {
  overflow: hidden;
  display: none;
}

.side__main__nav ul li a {
	position: relative;
	background-color: transparent;
	box-shadow: none!important;
}

.side__main__nav ul li:hover .sub__nav li a {
	background-color: transparent!important;
}

.side__main__nav ul li .sub__nav li:hover a {
	background-color: rgba(199, 199, 199, 0.2)!important;
}

.navbar-vertical .navbar-nav .nav-link.show::after {
	color: #CED4DA;
	transform: rotate(180deg);
} */

/* .side__main__nav .collapse ul li .sub__nav {
    max-height: 0;
    overflow: hidden;
    height: auto!important;
    display: block!important;
	transition: all 0.2s ease-in-out!important;
    -webkit-transition: all 0.2s ease-in-out!important;
    -moz-transition: all 0.2s ease-in-out!important;
}

.side__main__nav .collapse ul li .sub__nav.show {
    max-height: 1000px;
	transition: all 0.2s ease-in-out!important;
    -webkit-transition: all 0.2s ease-in-out!important;
    -moz-transition: all 0.2s ease-in-out!important;
    height: auto!important;
    display: block!important;
}

.side__main__nav .collapse ul li .nav-link[data-bs-toggle="collapse"]::after, .side__main__nav .collapse ul li .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]::after {
	transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
} */

/* .view__details__area .card .card-body .material-icons-round {
	font-size: 17px;
}

.lisiting__table__new table tr td .action__btns .material-icons {
	font-size: 17px !important;
} */

.month__year__option {
	width: 100%;
}

.action__btns__new .dropstart[data-tippy-content="Close & Back"] i.material-icons {
	font-size: 34px !important;
	margin: -6px 0 0;
	position: relative;
	top: -1px;
}

.card-icons i.fa {
	font-size: 18px;
}

.social__icons a img {
	max-width: 20px;
	margin: 0 4px 4px 0;
}

.sorting__tags {
	display: flex;
	align-content: center;
	align-items: center;
}



.tags__selected__option {
	width: fit-content;
	margin: 0 0 0 6px;
}

.tags__selected__option .select-selected {
	min-width: 90px;
	padding-bottom: 0.4rem;
	padding-top: 4px;
}

.tags__selected__option .select-items {
	min-width: 150px;
}

.custom-select.tags__selected__option .select-selected::after{
	right: 3.5px;
}

.tags__block .input__filter__tags {
	min-width: auto;
	width: fit-content;
	max-width: 90px;
	padding-left: 6px;
}

.tags__block .input__filter__tags .show .filters--listing {
	min-width: 150px;
}

.tags__block .input__filter__tags .form-clear {
	right: 0px;
}

.tags__block .filter-search-inner .form-control {
	padding-right: 15px !important;
	padding-bottom: 3px;
	font-size:13px;
}

.team__avtar .avatar {
	width: 30px !important;
	height: 30px !important;
}

.team__avtar h4 {
	font-size: 14px;
	text-align: center;
	line-height: 36px !important;
	font-weight: 900;
}

.help--icon {
	right: 55px;
}

.avtar__big {
	width: 45px !important;
	height: 45px !important;
}

.avtar__big h4 {
	font-size: 17px;
	text-align: center;
	line-height: 36px !important;
	font-weight: 900;
}

.lisiting__table__new .table-responsive {
        overflow-x: auto;
    overflow-y: hidden;
}

.bg-gradient-light:hover {
	box-shadow: 0 14px 26px -12px rgba(52, 71, 103, 0.4), 0 4px 23px 0 rgba(52, 71, 103, 0.15), 0 8px 10px -5px rgba(52, 71, 103, 0.2)!important;
}

.pr-17 {
  padding-right: 17px;
}


.new__header__options .filter-search-inner {
    min-width: 180px;
}


.table--inner--heading th {
	padding-top: 35px;
}

.manageteam__table .table th:first-child p {
	/* color: #344767; */
	color: #7b809a;
	font-weight: 500;
	font-size: 1.1rem;
}

.manageteam__table .table .name__area span {
	color: #7b809a !important;
}

.manageteam__table .table .table--inner--heading th {}

.delete--icon {
	position: absolute;
	right: 0;
	top: 8px;
}

.delete--icon i {
	font-size: 29px;
}

.delete--icon:hover {
	color: #e91e63 !important;
}

.ml-6 {
	margin-left: 6px;
}

.manageteam__table .table tr.border--none td {
	border-width: 0;
}

.filter__tags .badge {
	border: none;
	margin: 0 3px 7px;
}

.file__action__btns .badge {
	border: none;
}

.file__action__btns .badge {
	position: relative;
	top: 1px;
}

.file__action__btns .file--btn .material-icons {
	position: relative;
	top: -1px;
}

.filter__tags .badge.badge-secondary:hover, .file__action__btns .badge.badge-secondary:hover {
	border:1.5px solid #6c757d;
}
.filter__tags .badge.badge-secondary, .file__action__btns .badge.badge-secondary {
	border:1.5px solid transparent;
	/* 	transition: all 0.8s ease-in-out;
	-webkit-transition: all 0.8s ease-in-out;
	-moz-transition: all 0.8s ease-in-out; */
}

.filter__tags .badge.badge-success, .file__action__btns .badge.badge-success {
	border:1.5px solid transparent;
	/* 	transition: all 0.8s ease-in-out;
	-webkit-transition: all 0.8s ease-in-out;
	-moz-transition: all 0.8s ease-in-out; */
}

.filter__tags .badge.badge-success:hover, .file__action__btns .badge.badge-success:hover {
	border:1.5px solid #339537;
}	

/* .documents__listing .form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked {
	background: #bce2be;
}

.documents__listing .form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked, .documents__listing .form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
	border-color:#339537;
}

.documents__listing .form-check:not(.form-switch) .form-check-input[type="checkbox"]::after {
	color: #339537;
} */

.filter__btn {
	margin: 0;
	width: 100%;
	text-align: left;
	color: #495057;
	font-size: 14px;
	font-weight: 400;
	text-transform: none;
	line-height: 1.3;
	border: 1px solid #d2d6da !important;
	padding: 0.625rem 1.95rem 0.625rem 0.75rem !important;
	border-radius: 0.375rem;
}

.filter__btn:hover, .filter__btn:focus {
	color:#e91e63;
	/* background-color: #e91e63;*/
	/* border-color:#e91e63!important; */
	/*box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.4), 0 4px 23px 0 rgba(233, 30, 99, 0.15), 0 8px 10px -5px rgba(233, 30, 99, 0.2); */
}

.input__filter__tags__btn .filter__btn::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left:0;
	top:0;
	border-radius: 6px;
	border: 2px solid #e91e63;
	opacity:0;
	 transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}


.input__filter__tags__btn .filter__btn:focus::before, .input__filter__tags__btn .filter__btn:hover::before {
	opacity:1;
	 transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}

.input__filter__tags__btn .filter__btn:focus, .input__filter__tags__btn .filter__btn:hover {
	border:1px solid transparent!important;
}

.filters__right__panel {
	padding-left:1rem;
	padding-right:1rem;
}

.filter__title {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.candidate__list.mt-3.filters__block {
	float: left;
	width: 100%;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.filters__block .sider__pan__filters {
	width: 100%;
	float: left;
	margin-bottom: 20px;
}



.checkbox__grey.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked {
	background: #c5c7cd;
}

.checkbox__grey.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked, .checkbox__grey.form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
	border-color:#c5c7cd;
}

.checkbox__grey.form-check:not(.form-switch) .form-check-input[type="checkbox"]::after {
	color: #fff;
}

.table .select--dash .form-check-input {
	width: 20px;
	height: 20px;
}

.select--dash .form-check {
	padding: 0;
	margin: 0;
}

.select--dash .form-check:not(.form-switch) .form-check-input[type="checkbox"]::after {
	content: "\f068";
}

.fileuplaod__block.highlighted .form-control  {
	background:#f8f9fa;
}

.before__none li a.active::before {
	display:none;
}

.before__none li a {
	padding:0;
}

.before__none .nav-link.active, .before__none .nav-item.show .nav-link, .before__none .nav-link:hover, .before__none .nav-link:focus {
	color: #fff;
	font-weight: 900 !important;
	margin: 0;
}

.multisteps-form__panel .template__step .btn i {
	font-size: 3rem;
}

.card__hover__effect .card:hover  {
	background-color: rgba(248, 249, 250, 1);
	cursor: pointer;
}


.card__hover__effect .card:hover .card-header  {
	background-color: rgba(0, 0, 0, 0);
}

.modal {
	margin: 0 auto;
}

.modal-backdrop {
	opacity: 0;
	background: rgba(0, 0, 0, 0.4);
	visibility: hidden;
}

.modal-backdrop.show {
	opacity: 1;
	background: rgba(0, 0, 0, 0.4);
	visibility: visible;
}

.new__modals .modal-dialog {
	margin:0;
	pointer-events: auto;
	max-width: 100%;
}

.question__block ul li input[type="text"] {
	display: block; 
}

.question__block ul li {
	border-bottom-width: 0;
	/* padding-left: 28px; */
}

.drag__icon {
	position: absolute;
	left: 5px;
	top: 21px;
	display: none;
}

.question__block ul li:hover .drag__icon {
	display: block;
}

.drag__icon .material-icons {
	cursor: all-scroll;
}

.drag__icon .material-icons:hover {
	color: #e91e63 !important;
}

.question__block ul li:last-child {
	border-bottom-width: 1px;
	padding-bottom: 25px;
}

.question__block ul li textarea {
	padding-right: 100px;
	height: 40px;
	resize: none;
	min-height: fit-content;
	padding-bottom: 10px;
}

.sort__icons {
	display: flex;
	align-items: center;
	align-content: center;
	position: absolute;
	right: 10px;
	top: 23px;
	z-index: 123;
}

.question__sort__icon {
	display: flex;
	align-items: center;
	align-content: center;
}

.question__sort__icon .actn__btn__in {
	margin: 0;
	border: none;
	background: transparent;
	padding: 0;
}

.list-group.ui-sortable {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top-width: 0;
	border-bottom-left-radius: 0.75rem;
	border-bottom-right-radius: 0.75rem;
}

.question__head {
	background: #e91e63;
	padding: 0.5rem 1rem;
	border-radius: 0.75rem 0.75rem 0 0;
	border: 1px solid #e91e63;
	border-bottom-width: 0;
	padding-right: 40px;
}

.question__head h3 {
	color: #fff;
	font-family: var(--bs-body-font-family);
	font-size: var(--bs-body-font-size);
	margin:0;
}

.show__checkbox {
	display: flex;
	justify-content: center;
	margin: 12px 0 0;
	align-content: center;
	align-items: center;
}

.show__checkbox .form-check {
	margin: 0;
	padding: 0;
}

.show__checkbox .form-check label {
	color: #545454;
}

.step__choose label {
    min-height: 130px;
    padding: 0!important;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 160px;
    margin:0 auto 1rem;
}


/* .question__list__block textarea::-webkit-input-placeholder {
  color: #495057;
}
.question__list__block textarea::-moz-placeholder { 
  color: #495057;
}
.question__list__block textarea:-ms-input-placeholder { 
  color: #495057;
}
.question__list__block textarea:-moz-placeholder {
  color: #495057;
} */

.modal.new__modals {
	position: fixed;
	width: 100%;
	margin: 0 auto;
	height: 100%;
	padding: 2% 0;
	background: transparent;
}

.new__modals .modal-dialog {
	width: 32em;
	max-width: 100%;
	padding:0 0 1.25em;
	border: none;
	border-radius:0.75rem;
	background: #fff;
	font-family: inherit;
	font-size: 1rem;
	margin:0 auto!important;
}

.modal {
	display: flex !important;
	visibility: hidden;
	align-items: center;
	align-content: center;
}

.modal.show {
	visibility:visible;
	 -webkit-animation: swal2-show 0.3s;
  animation: swal2-show 0.3s;
	
}

.modal.show .swal2-icon.swal2-warning.swal2-icon-show {
    -webkit-animation: swal2-animate-error-icon .5s;
    animation: swal2-animate-error-icon .5s;
}

.modal.show .swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
    -webkit-animation: swal2-animate-i-mark .5s;
    animation: swal2-animate-i-mark .5s;
}

.question__list__block .editable__content {
	resize: none;
	min-height: 353px;
}

/* .editable__content b {
	font-size: 1rem;
	line-height: 1.625;
	color: #344767;
} */

/* .editable__content {
	font-weight: 500;
} */

.editable__content b {
	font-size: 0.875rem;
	line-height: 1.625;
	color: #495057;
	font-weight: 500;
}

.edit__question__icon {
	position: absolute;
	right: 0;
	top: 2px;
}

.edit__question__icon a:hover i {
	color: #e91e63 !important;
}

.question__list__inner__block {
	position: relative;
	/* padding-right: 35px; */
	padding-right: 60px;
	margin-bottom: 35px;
}

.question__list__inner__block .custom-select {
	width: 100%;
}

.question__list__inner__block .custom-select .select-selected {
	color: #e91e63;
	font-weight: 500;
	padding-right: 35px;
}

/* .question__list__outer {
	max-height: 425px;
	overflow: auto;
} */

.text--color--red  {color:#e91e63;}

/* .lisiting__table__new table.table thead th:nth-child(4), .lisiting__table__new table.table tr td:nth-child(4) {
    min-width: 310px;
} */

.lisiting__table__new table.table thead th:nth-child(4), .lisiting__table__new table.table tr td:nth-child(4) {
min-width: 310px;
max-width: 310px;
white-space: normal;
}

.lisiting__table__new table.table thead th:nth-child(5), .lisiting__table__new table.table tr td:nth-child(5) {
    max-width: 200px;
    min-width: 200px;
    white-space: inherit;
}

.font-weight-500 {
   font-weight: 500;
}

.candidate--date--picker {
	padding: 0 15px 0 0;
	margin: 30px 0 10px;
}

/***************************************** 
	Date Picker New Css Start
******************************************/

html, body {
	height: 100%;
}

.date__picker {
	display: none;
}

.candidate--date--picker.show .date__picker {
	display: block;
}

.candidate--date--picker .input-group {
	position: relative;
}

.date__picker {
	position: absolute;
	bottom: 50px;
	text-align: center;
	padding: 0;
	-webkit-animation: none;
	animation: none;
	direction: ltr;
	border: 0;
	font-size: 14px;
	line-height: 24px;
	border-radius: 0.75rem !important;
	width: 275px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	background: #fff;
	-webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	margin: 0 auto;
	left: 10px;
}

.date__picker .picker {
	width: 100%;
	border: none;
	background: transparent;
}

.date__picker .picker select {
	padding: 0 0;
	margin: 7px 6px 2px;
	width: 37%;
	font-weight: 300;
	font-size: 16px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.date__picker .picker-d table tr.picker-d-h td {
	cursor: default;
	font-size: 14px;
	background: transparent;
	line-height: 1;
	margin: 0;
	text-align: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	/* font-size: 13px; */
}

.date__picker .picker-d table tr td.picker-d-d {
	background: none;
	border: 1px solid transparent;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #344767;
	cursor: pointer;
	font-weight: 400;
	/* width: 14.2857143%; */
	-webkit-flex-basis: 14.2857143%;
	-ms-flex-preferred-size: 14.2857143%;
	flex-basis: 14.2857143%;
	/* max-width: 39px; */
	height: 28px;
	line-height: 28px;
	margin: 0;
	position: relative;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	font-size: 13px;
	width: 26px;
	padding: 3px;
	max-width: 28px;
	min-width: 28px;
}

.date__picker .picker-d table tr td.picker-d-b {
	background: transparent;
}

.date__picker .picker-d table tr td.picker-d-d:hover, .picker-d table tr td.picker-d-d:focus {
	background: rgba(94, 114, 228, 0.28);
	border: none;
}

.date__picker .picker-d table tr td.picker-d-td, .date__picker .picker-d table tr td.selected {
	background: #e91e63 !important;
	color: #fff;
}

/***************************************** 
	Date Picker New Css End
******************************************/



.datepicker.datepicker-dropdown {
	text-align: center;
	padding: 0;
	-webkit-animation: none;
	animation: none;
	direction: ltr;
	border: 0;
	font-size: 14px;
	line-height: 24px;
	border-radius: 0.75rem;
	width: 307.875px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	background: #fff;
	-webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.datepicker.datepicker-dropdown .table-condensed {
	width: 100%;
}

.datepicker.datepicker-dropdown .table-condensed thead tr th.datepicker-switch {
	padding-bottom: 25px;
	padding-top: 15px;
	font-size: 19px;
	font-weight: 300;
	background: transparent;
}

.datepicker.datepicker-dropdown .table-condensed thead tr th.datepicker-switch:hover {
	color:#e91e63; 
}

.datepicker.datepicker-dropdown .table-condensed thead tr th.dow {
	cursor: default;
	font-size: 14px;
	background: transparent;
	color: rgba(0, 0, 0, 0.54);
	line-height: 1;
	margin: 0;
	text-align: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.datepicker.datepicker-dropdown .table-condensed tbody tr td.day {
	background: none;
	border: 1px solid transparent;
	border-radius: 150px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #344767;
	cursor: pointer;
	font-weight: 400;
	width: 14.2857143%;
	-webkit-flex-basis: 14.2857143%;
	-ms-flex-preferred-size: 14.2857143%;
	flex-basis: 14.2857143%;
	max-width: 39px;
	height: 39px;
	line-height: 39px;
	margin: 0;
	position: relative;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.datepicker.datepicker-dropdown .table-condensed tbody tr td.new, .datepicker.datepicker-dropdown .table-condensed tbody tr td.old {
	opacity:0.3;
}

.datepicker.datepicker-dropdown .table-condensed tbody tr td.day:hover, .datepicker.datepicker-dropdown .table-condensed tbody tr td.day:focus {
	background: rgba(94, 114, 228, 0.28);
	border: none;
}


.datepicker.datepicker-dropdown .table-condensed tbody tr td.active.day {
	background: #e91e63 !important;
	color: #fff;
	border: none;
}

.datepicker-dropdown.datepicker-orient-top::before {
	bottom: -7px;
	border-bottom: 0;
	border-top: 12px solid #fff;
	right: auto;
	left: 31px !important;
}

.datepicker-dropdown::before {
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
}

.datepicker-dropdown.datepicker-orient-left::after {
	display: none;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active.disabled:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active[disabled] {
	background-color: #e91e63;
	background-image: none;
}

.candidate--date--picker label {
	width: 100%;
	margin: 0 0 2px;
}

.ques__edit__icon {
	position: absolute;
	right: 13px;
	top: 6px;
}

.ques__edit__icon a i {
	color:#fff;
}

.add--ques--btn {
	/* margin: 0 auto; */
	/* display: flex; */
	border: 1.5px solid transparent;
	font-size: 13px;
	padding: 9px 13px;
}

.add--ques--btn:hover {
	border: 1.5px solid #339537;
}

.interview--title--text p {
	font-weight: 400;
}

.interview--title--text {
	padding-right: 17px;
}

/* .candidate__card__table tr td .action__btns .dropstart .dropdown-menu {
	margin-top: 25px !important;
	-moz-transform: scale(0)!important;
-o-transform: scale(0)!important; 
-webkit-transform: scale(0)!important;
transform: scale(0)!important;
transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
}

.candidate__card__table tr td .action__btns .dropstart .dropdown-menu.show {
	-moz-transform: scale(1)!important;
-o-transform: scale(1)!important; 
-webkit-transform: scale(1)!important;
transform: scale(1)!important;
} */

.margin--bottom--less {
	margin-bottom: 15px;
}

.question__list__block .resizefield {
	min-height: 340px;
}

.info--card--inner p {
	font-weight: 400;
	font-size: 0.875rem;
	width: 100%;
	float: left;
	margin-top: 10px;
}

.logo__small span img {
	min-width: 25px;
	max-width: 25px;
}

.info--card--inner .avatar {
	float: left;
	margin-right: 10px;
}

.info--card--inner .card {
	min-height: 170px;
}

.add--question p {
	color:rgba(73, 80, 87, 0.5);
	margin: 5px 0 0;
	font-size: 0.875rem;
	font-weight: 400;
}

.sidepane--btn .add--ques--btn {
	border-radius: 0.375rem;
	height: auto;
	width: auto;
	border: 1.5px solid transparent;
	font-size: 13px;
	padding: 9px 13px;
}

.sidepane--btn .add--ques--btn:hover {
	border: 1.5px solid #339537;
}

.view__details__area .dropdown .dropdown-menu {
    z-index: 99999;
}

/* .new__modals .password__modal__block {
	max-width: 410px; 
	width: 100%;
	padding: 0 0px;
} */

.new__modals .password__modal__block {
	max-width: none;
	width: 100%;
	padding: 0 0px;
	border-radius: 0;
	background: transparent;
}

/* .new__modals .password__modal__block .card-body {
	padding: 2rem 1.5rem;
} */

.modal__btns {
	row-gap: 10px;
}

.interview--title--text .input-group.input-group-dynamic.is-filled .form-label {
	top: -0.6rem;
}

.side__nav__hr__spacing hr.horizontal {
	width: 95%;
}

.question__list__block .min-height-382 {
	min-height: 382px;
}

.mt-15 {
	margin-top: 15px;
}

.mr-8 {
	margin-right: 8px;
}

.add--ques--icon {
	position: relative;
}

.add--ques--icon i {
	font-size: 19px;
	position: absolute;
	right: 0;
	top: -0.7px;
}

.minheight-373 {
    min-height: 373px!important;
}

.scroll__bar__height.side__nav__hr__spacing {
    padding-left: 2px;
}

.blink--cursor {
	position: relative;
}

.blink--cursor i {
	position: absolute;
	width: 1.2px;
	height: 18px;
	background-color: #000;
	left: 0px;
	bottom: 10px;
	animation-name: blink;
	animation-duration: 800ms;
	animation-iteration-count: infinite;
	opacity: 1;
}

.blink--cursor input:focus + i {
	display: none;
}

@keyframes blink {
	from { opacity: 1; }
	to { opacity: 0; }
}

.swal2-container {
    z-index: 1234567;
}

/* .full--width--modal .right__nav__bar {
	box-shadow: none !important;
	background: #f0f2f5 !important;
} */

.navbar.right__nav__bar.sticky-details-popup {
	box-shadow: none !important;
	background: rgba(255, 255, 255, 0) !important;
}

/* .full--width--modal .navbar.right__nav__bar {
    position: fixed!important;
    width: calc(100% - 21.125rem);
    right: 1.1rem;
    right: 17px;
	transition: all 0.2s ease-in-out;
} */

/* .g-sidenav-hidden .full--width--modal .navbar.right__nav__bar, .g-sidenav-show.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar {
    width: calc(100% - 11.525rem);
} */

.full--width--modal .navbar.right__nav__bar {
    /* position: fixed!important;
    width: calc(100% - 21.125rem); */
    /* right: 1.1rem; */
    /* right: 17px; */
    /* margin-right: 1.5rem!important; */
    /* padding: .5rem 1rem!important; */
	/* transition: all 0.2s ease-in-out; */
    margin-right: 0!important;
    float: right;
	margin-left: 0!important;
    /* padding: 0.5rem 0.8rem .5rem 1rem!important; */
    padding:.5rem 0 .5rem 0rem!important;
	width:100%;
	position: sticky!important;
}

.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar, .g-sidenav-show.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar.sticky-details-popup {
   /*  width: 96.4%; */
    width: 100%;
}

.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar, .g-sidenav-show.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar {
    width: 100%;
}

/* .full--width--modal nav.navbar.navbar-main.navbar-expand-lg.position-fixed.right__nav__bar.nav__with__slider__option.new__header__bar.blur.shadow-blur {
    position: fixed;
    right: 0;
    width: calc(100% - 10.525rem);
} */

.g-sidenav-hidden .full--width--modal nav.navbar.navbar-main.navbar-expand-lg.position-fixed.right__nav__bar.nav__with__slider__option.new__header__bar.blur.shadow-blur {
    /* width: calc(100% - 10.425rem)!important; */
    width: 100%!important;
	right:0;
}

.g-sidenav-pinned .full--width--modal nav.navbar.navbar-main.navbar-expand-lg.position-fixed.right__nav__bar.nav__with__slider__option.new__header__bar.blur.shadow-blur, .g-sidenav-show .full--width--modal nav.navbar.navbar-main.navbar-expand-lg.position-fixed.right__nav__bar.nav__with__slider__option.new__header__bar.blur.shadow-blur {
   /*  width: calc(100% - 20.125rem); */
    width:100%;
	right:0;
	
}

.g-sidenav-pinned .full--width--modal nav.navbar.navbar-main.navbar-expand-lg.position-fixed.right__nav__bar.nav__with__slider__option.new__header__bar.blur.shadow-blur {
   /*  width: calc(100% - 21.125rem); */
    width:100%;
}

/* .g-sidenav-pinned .full--width--modal .navbar.right__nav__bar, .g-sidenav-show .full--width--modal .navbar.right__nav__bar {
	width: calc(100% - 21.125rem);
} */

.fixed-plugin .card {
	z-index: 123456;
}

.full--width--modal {
	z-index: 123456;
	display: unset !important;
	animation: none !important;
	-webkit-animation: none !important;
	width: calc(100% - 17.5rem); 
	right: 0;
	left: auto;
	transition: all 0.2s ease-in-out;
}

.g-sidenav-pinned .full--width--modal {
	width: calc(100% - 17.5rem); 
}

.g-sidenav-hidden .full--width--modal {
	width: calc(100% - 7.825rem);
	transition: all 0.2s ease-in-out;
}

.full--width--modal .container-fluid.px-md-4 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

.full--width--modal .modal-dialog {
    width: 100%;
    height: 100%;
    background: #f0f2f5;
	padding:0 25px 0 18px;
}

.full--width--modal .main-content {
	position: relative;
	display: inline-block;
	width: 100%;
	pointer-events: auto;
	background-color: #f0f2f5;
	background-clip: padding-box;
	border: none;
	border-radius: 0.5rem;
	outline: 0;
	padding:0.2rem 0.7rem ;
	/* padding-top: 100px!important; */
}

.full--width--modal .card {
    box-shadow: none;
    width: 100%;
}

.modal-backdrop.show {
	opacity: 0;
	visibility: hidden;
}

.full--width--modal {
	display: none !important;
}

.full--width--modal.show {
	display: block !important;
}




@-moz-document url-prefix() { 
  .right__nav__bar  {
	background-color: rgba(255, 255, 255, 0) !important;
	}
	
	.right__nav__bar.shadow-blur  {
	background-color: rgba(255, 255, 255, 0.99) !important;
	}
}

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 

	@media {
		 .right__nav__bar  {
		background-color: rgba(255, 255, 255, 0) !important;
		}
		
		.right__nav__bar.shadow-blur  {
		background-color: rgba(255, 255, 255, 0.99) !important;
		}
	}
}


.language__dropdown  {
	position: relative;
	top: 5px;
	min-width: 160px!important;
}

.progress__bar__inner {
	width: 100%;
	display: flex;
	height: 4px;
	overflow: hidden;
	background-color: #dee2e6;
	border-radius: 0.125rem;
	position: relative;
	top: 4px;
}

.progress__bar__inner span {
	display: flex;
	height: 100%;
	background-color:#d63384;
}

.personal--edit--form .custom-select .select-selected {
	padding-top: 0.5rem;
}

.detail--edit--icon {
	position: absolute;
	right: 31px;
	top: 25px;
	padding: 0;
}

.personal--detail__block .select-items {
	margin-top: 7px;
}

.company__cards .avatar h4 {
	color: #fff;
}

.company__step__form {
	min-height: 350px;
}

.company__step__form .avatar {
	margin: 0 auto;
	display: flex;
}

.settings__form .input-group.input-group-dynamic.is-filled label {
	color: #bbb9b9;
}

.feed-cards .card {
	min-height: 196px;
}

.feed-cards .card .card-icons a {
	display: flex;
	align-content: center;
	align-items: center;
}

.feed-cards .avatar span {
	font-size: 36px;
}

.table-title-right {
	position: absolute;
	right: 0;
	display: flex;
	align-items: center;
	align-content: center;
}

.table-title-right .delete--icon {
	position: relative;
	top: auto;
	right: auto;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	margin-left: 25px;
}

.table-title-right .form-check.form-switch {
	padding-left: 0;
	padding-right: 2.375rem;
	margin: 0;
	position: relative;
}

.table-title-right .form-check.form-switch label {
	margin-bottom:0;
	margin-left: 0;
}

.table-title-right .form-check.form-switch .form-check-input {
	top: 6px;
	position: absolute; 
	right: 0;
	margin: 0;
}

.settings__form .avatar .btn {
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.manageteam__table table tr td p {
	margin: 0;
	text-align: center;
}

.access_btn .form-check.form-switch {
	padding-left: 0;
	padding-right: 2.375rem;
	margin: 0;
	position: relative;
	width: max-content;
}

.access_btn .form-check.form-switch label {
	margin-bottom:0;
	margin-left: 0;
}

.access_btn .form-check.form-switch .form-check-input {
	top: 6px;
	position: absolute; 
	right: 0;
	margin: 0;
}

.view__details__area .card {
	min-height: 130px;
}

.team_member_table table.table thead tr th:nth-child(2), .team_member_table table.table tbody tr td:nth-child(2) {
	max-width: 300px;
	text-align: left;
	min-width: 300px;
}

.team_member_table table.table thead th:last-child, .team_member_table table.table tr td:last-child {
	max-width: 100px;
	min-width: 100px;
}

.team_member_table table.table thead th:nth-child(4), .team_member_table table.table tr td:nth-child(4) {
	min-width: 150px;
	max-width: 150px;
}

.table-title-right .resend-icon {
	top: 2px;
}

.candidate_group_table table.table thead tr th:first-child, .candidate_group_table table.table tbody tr td:first-child {
	width: auto;
	text-align: left;
}

.candidate_group_table table.table thead th:last-child, .candidate_group_table table.table tr td:last-child {
	min-width: 70px;
	max-width: 70px;
}

.update_email_modal .modal-dialog {
	width: 100%;
	max-width: 408px;
	padding: 0;
	border-radius: 0;
	background: transparent;
	overflow: visible;
}

.update_email_modal .modal-dialog .card {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.06);
}

.reset__password__form .input-group.input-group-outline.is-focused .form-label {
	/* background: #fff; */
	/* background-color: #fff;
    padding: 0 3px; */
}	



/* .reset__password__form .input-group.input-group-outline .form-label {
    left: 0.625rem;
	width:auto;
}

.reset__password__form .input-group.input-group-outline .form-label:before, .reset__password__form .input-group.input-group-outline .form-label:after	 {
	display: none;
}

.reset__password__form .input-group.input-group-outline.is-focused .form-label+.form-control {
	box-shadow: inset 1px 1px #212529, inset 1px -1px #212529, inset -1px 1px #212529, inset -1px -1px #212529;
	border-top-color:#212529!important;
}



.reset__password__form .input-group.input-group-outline .form-control {
	transition: none;
} */

.cards_edit {
	top: 21px;
	right: 25px;
}

.edit__icon .bg-gradient-primary-hover {
	transition: none;
}

.edit__icon .bg-gradient-primary-hover:hover {
	background-color: #e91e63;
	border-color: #e91e63;
	box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.4), 0 4px 23px 0 rgba(233, 30, 99, 0.15), 0 8px 10px -5px rgba(233, 30, 99, 0.2);
	color:#fff;
	opacity: 1;
}

.edit__icon .bg-gradient-primary-hover:hover i {
	color: #fff !important;
}

.work-btns {
	position: absolute;
	right: 0px;
	width: auto;
	z-index: 12;
	top: 3px;
	visibility: hidden;
}

.work-btns .material-icons:hover {
	color:#e91e63!important;
}

.experience_row:hover .work-btns {
	visibility: visible;
}

.input-group .form-control.input-h-40 {
	height: 40px;
}

.input-group .form-control.input-m-h-40 {
	min-height: 40px !important;
}

.input-group .form-control.input-h-40 {
	height: 40px !important;
}

.input-group .form-control.input-h-100 {
	height: 100px !important;
}

.input-group .form-control.input-m-h-100 {
	min-height: 100px !important;
}

.input-group .form-control.input-h-200 {
	height: 200px !important;
}

.input-group .form-control.input-m-h-200 {
	min-height: 200px !important;
}


.rotate-90 {
	transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg); 
	-webkit-transform: rotate(90deg);
}

.social_links_fields.show {
	display: flex!important;
}

.detail_fields_block.hide {
	display: none!important;
}

.personal--edit--form .input-group.is-filled label.form-label {
	color:#ced4da!important;
}

.cv-edit .detail--edit--icon {
	top: 20px;
}

.cv-edit .detail--edit--icon i.fas {
	top: 1px;
	position: relative;
}

.navbar-vertical.side__main__nav .navbar-nav .nav-link .avtar-icon {
	width: 30px;
	min-width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.navbar-vertical.side__main__nav .navbar-nav .nav-link .avtar-icon .material-icons {
	font-size: 17px;
	text-align: center;
	margin: 0;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	color: #fff;
}

.circle-yellow {
	background: #f4da03!important;
}

.text-black { color: #344767; }

.personal-help-btn {
	display: none;
}

.edit--detail-card {
	display: none;
}

.personal--edit--form .edit--detail-card {
	display: block;
}

.personal--edit--form .view--detail-card {
	display: none;
}

.personal--edit--form #edit-personal-fields {
	display: none;
}

.personal--edit--form .personal-help-btn {
	display: block;
}


.heading-help {
	display: none;
}

.heading-edit-card {
	display: none;
}

.heading--edit--form .heading-edit-card {
	display: block;
}

.heading--edit--form .heading-view {
	display: none;
}

.heading--edit--form .heading-edit {
	display: none;
}

.heading--edit--form .heading-help {
	display: block;
}


.summary-help-btn {
	display: none;
}

.summary--edit-form {
	display: none;
}

.summary--edit--form .summary--edit-form {
	display: block;
}

.summary--edit--form .summary--detail {
	display: none;
}

.summary--edit--form .summary-edit-btn {
	display: none;
}

.summary--edit--form .summary-help-btn {
	display: block;
}


.wrk-exp-help-btn {
	display: none;
}

.wrok-exp-add {
	display: none;
}

.wrk-exp-add-form .wrok-exp-add {
	display: block;
}

.wrk-exp-add-form .wrk-exp-add-btn {
	display: none;
}

.wrk-exp-add-form .wrk-exp-help-btn {
	display: block;
}


.education-help-btn {
	display: none;
}

.education-add {
	display: none;
}

.education-add-form .education-add {
	display: block;
}

.education-add-form .education-add-btn {
	display: none;
}

.education-add-form .education-help-btn {
	display: block;
}

.personal-skill-help-btn {
	display: none;
}

.personal-skill-edit {
	display: none;
}

.personal-skill-form .personal-skill-edit-btn {
	display: none;
}

.personal-skill-form .personal-skill-help-btn {
	display: block;
}

/* .personal-skill-form .personal-skill-view-card {
	display: none;
} */
.personal-skill-form .personal-skill-edit {
	display: block;
}

.skills-listing {
	display: flex;
	flex-wrap: wrap;
	/* border-bottom: 1px solid #f5f5f5; */
	padding: 10px 0;
	position: relative;
}

/* .skills-listing:last-child {
	border-bottom: none;
} */

.skills-listing:hover .skill-edit-inr-btns {
	visibility: visible;
}

.skill-edit-inr-btns {
	top: 11px;
}

.level-text {
	padding-right: 120px;
}

.level-options .select-items div:first-child {
	opacity: 0.4;
}

.Interest-help-btn {
	display: none;
}

.Interest-edit {
	display: none;
}

.Interest-form .Interest-edit-btn {
	display: none;
}

.Interest-form .Interest-help-btn {
	display: block;
}

.Interest-form .Interest-edit {
	display: block;
}

.licence-help-btn {
	display: none;
}

.licence-edit {
	display: none;
}

.licence-certification-form .licence-edit-btn {
	display: none;
}

.licence-certification-form .licence-help-btn {
	display: block;
}

.licence-certification-form .licence-edit {
	display: block;
}

.publications-help-btn {
	display: none;
}

.publications-edit {
	display: none;
}

.publications-form .publications-edit-btn {
	display: none;
}

.publications-form .publications-help-btn {
	display: block;
}

.publications-form .publications-edit {
	display: block;
}

.references-help-btn {
	display: none;
}

.references-edit {
	display: none;
}

.references-form .references-edit-btn {
	display: none;
}

.references-form .references-help-btn {
	display: block;
}

.references-form .references-edit {
	display: block;
}

.level-options .select-items {
	margin-top: 12px;
}

.multisteps-form__content textarea.form-control{
    resize: none;
    color: #7b809a;
   /* font-weight: 300;  */
}

.cv-edit .input-group input.form-control, .cv-edit .custom-select .select-selected {
    color: #7b809a;
    /* font-weight: 300; */
}

.topfixed__bar {
	/* height: calc(100vh - 150px); */
	overflow: auto;
}

.topfixed__bar.top-sticky {
	/* height: calc(100vh - 40px); */
}

.company__cards .card.active, .company__cards .card.active .card-header {
	background-color: #dddee1!important;
}

.scrolling__section .topfixed__bar::-webkit-scrollbar {
  width: 5px; 
  height: 7px;
}

.scrolling__section .topfixed__bar::-webkit-scrollbar-thumb {
  background-color: #dddee1;
  outline: none;
  border-radius: 50px;
}

.scrolling__section .topfixed__bar::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 50px;
  overflow:hidden;
}

.calender-box {
	display: block;
	opacity: 0;
	transform-origin: 0 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: visible;
	will-change: 0 0;
	border: none;
	border-radius: 0.5rem!important;
	box-shadow:0px 5px 10px 6px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	padding:1rem 1rem;
	transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	position: absolute;
	width: 280px;
	 left: 0;
	 top: 50px;
	background: no-repeat bottom, 50% calc(100% - 1px);
	background-size: 0 100%, 100% 100%;
	background-color: #fff;
	z-index: 9;
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
}

.calender-select.is-show .calender-box {
	opacity: 1;
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1);
}

.calender-box::before {
	font-family: "FontAwesome";
	content: "\f0d8";
	position: absolute;
	top: -20px;
	left: 28px;
	right: auto;
	font-size: 22px;
	color: #fff;
	transition: top 0.35s ease;
}

.year-btns input.form-control {
	max-width: 100px;
	background-image: none !important;
	font-size: 16px;
	font-weight: 500;
}


.year-btns input::-webkit-outer-spin-button,
.year-btns input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

.year-btns input[type="number"] {
        -moz-appearance: textfield;
      }

.year-btns {
	display: flex;
	align-items: center;
	align-content: center;
	position: relative;
	border-bottom: 1px solid #d2d2d2;
}

.year-btns .btn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #7b809a;
	padding: 0;
	margin: 0 3px;
	border: 1px solid #dee2e6;
	border-radius: 50% !important;
	width: 30px;
	height: 30px;
	font-size: 0.875rem;
	position: absolute;
	right: 0;
	transition: none;
}

.year-btns .btn.left-btn {
	right: 40px;
}

.year-btns .btn:hover {
	background: transparent;
	background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%);
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09), 0 2px 3px -1px rgba(0, 0, 0, 0.07);
	color: #fff;
	border: none;
}

.calender-months {
	width: 100%;
	margin: 15px 0 0;
}

.calender-months ul {
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3,1fr);
	justify-content: center;
	align-content: center;
	align-items: center;
	row-gap: 10px;
	column-gap: 45px;
	text-align: center;
}

.calender-months ul li {
	list-style: none;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	color: #7b809a;
	font-size: 0.875rem;
	width: 43px;
	height: 43px;
	border-radius: 50% !important;
	cursor: pointer;
}

.calender-months ul li:hover, .calender-months ul li.active {
	color: #fff;
	background: transparent;
	background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%);
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09), 0 2px 3px -1px rgba(0, 0, 0, 0.07);
}

.calender-months ul li.disable {
	background: #f0f2f5;
	color: #7b809a;
	pointer-events: none;
	cursor: none;
	opacity: 0.6;
}

.current-toggle {
	border-top: 1px solid #d2d2d2;
	margin-top: 15px;
	padding-top: 15px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: flex-end;
}

.lisiting-with-dropdown ul {
	padding: 0;
	margin: 0;
}

.lisiting-with-dropdown ul li {
	list-style: none;
	position: relative;
	padding-right: 30px;
	display: inline-block;
	width: 100%;
}

.lisiting-with-dropdown .dropdown {
	position: absolute;
	right: 16px;
	top: -3px;
}

.lisiting-with-dropdown ul li a.form-label {
	margin-bottom: 0;
}

.lisiting-with-dropdown .dropdown .dropdown-menu.dropdown-menu-end {
	right: 22px !important;
	left: auto !important;
	margin-top: 30px !important;
}

.label-full-width .form-check .custom-control-label {
	max-width: 100%;
	margin-left: 0;
}

.note-by h6 {
	margin: 0 8px 0 0;
}

.note-list {
	border-radius: 0.5rem;
	margin-bottom: 5px;
}

.note-list:hover {
	background: #f8f9fa;
}

.note-hover {
	position: absolute;
	right: 8px;
	top:-6px;
	background: #fff;
	padding: 0px 10px 2px;
	border-radius: 0.4rem;
	border: 1px solid #dfdfdf;
	display: flex; 
	align-content: center;
	justify-content: center;
	column-gap: 8px;
	visibility:hidden;
}

.note-list:hover .note-hover {
	visibility:visible;
}

.policy-text p {
	font-size: 0.875rem;
}

.stepsform__inner .is-filled .form-label {
    color: #bbb9b9;
}

.invite-popup-form {
	padding: 1em 2em 0;
}

.invite-popup-form .form-group {
	margin-bottom: 15px;
}

.invite-popup-form .form-group .form-control {
	width: 100%;
	margin: 0;
	padding: 7px 15px;
	height: auto;
	border-radius: 6px;
}

.invite-popup-form .form-group textarea.form-control {
	min-height: 150px;
	max-height: 150px;
	resize: none;
	border-radius: 6px;
}

.invite-popup-form .form-group .form-control:focus {
	border-color: #e91e63 !important;
	box-shadow: inset 1px 0 #e91e63, inset -1px 0 #e91e63, inset 0 -1px #e91e63, inset 0 1px #e91e63;
	 
	
}

.chat-block {
	overflow: hidden;
	height: 100%;
	position: relative;
	padding-bottom: 70px;
	padding-top: 5px;
}

.chat-body {
	padding: 8px 5px;
}

.chat-card {
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0; 
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(0, 0, 0, 0.125);
	border-radius: 0.375rem;
	max-width: 255px;
}

.chat-card p {
	font-size: 0.8rem;
	line-height: 1.425;
}

/* .chat-footer {
	padding: 0 5px;
	margin-top: 30px;
	width: 293px;
	position: fixed;
	bottom: 30px;
	background: #fff;
} */

.chat-footer {
	padding: 30px 5px 0;
	margin-top: 0;
	width: 293px;
	position: absolute;
	bottom: 0;
	background: #fff;
}

.chat-upper-block {
	height: calc(100vh - 210px);
	overflow-x: hidden;
	overflow-y: auto;
}

.chat-footer .form-control {
	resize: none;
	max-height:70px;
	min-height: 41px;
	display:flex;
	align-items:center;
	font-size: 0.8rem;
	font-weight: 300;
	color: var(--bs-body-color);
}

.chat-footer .input-group.input-group-outline.is-filled .form-label {
	color: #bbb9b9;
}	

.chat-footer .form-control::-webkit-input-placeholder {
    line-height: 240% !important;
}
.chat-footer .form-control:-moz-placeholder {
   line-height: 240% !important;
}
.chat-footer .form-control::-moz-placeholder {
   line-height: 240% !important;
}
.chat-footer .form-control:-ms-input-placeholder {
    line-height: 240% !important;
}

.chat-footer .input-group.focused.is-focused .form-label, .chat-footer .input-group.is-filled .form-label {
	opacity: 0;
	visibility: hidden;
}

.chat-footer .input-group .form-label {
	transition: none;
}

.chat-footer .input-group.input-group-outline.is-focused .form-label + .form-control,  .chat-footer .input-group.input-group-outline.is-filled .form-label + .form-control {
  box-shadow:inset 1px 0 #e91e63, inset -1px 0 #e91e63, inset 0 -1px #e91e63, inset 0 2px #e91e63;

}

.documnets__upload__block .custom-select .select-items {
	top: 50px;
}

.publish-btn .bg-gradient-light {
	transition: none;
	box-shadow: none!important;
	
}

.publish-btn .bg-gradient-light:hover, .publish-btn .bg-gradient-light:focus, .publish-btn .bg-gradient-light.active {
	background-color: #344767;
	border-color: #344767;
	background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
	color: #fff;
	
}

.my-cv-header {
	position: fixed !important;
	top: 24px !important; 
	/* top: 10px !important; */
	margin-top: 0 !important;
	width: calc(100% - 20.125rem);
	right: 0;
	left: auto;
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}
	
.g-sidenav-hidden .my-cv-header {
    width:calc(100% - 10.525rem);
	
}

.g-sidenav-hidden .navbar-vertical:hover.fixed-start + .main-content .my-cv-header {
	width: calc(100% - 20.125rem);
}

.content-scroll {
     height: 100em; 
    overflow-y: auto;
    padding-bottom: 20px;
}

.content-scroll::-webkit-scrollbar { 
  width: 0 !important;
  display: none; 
}
	
.fix-header-section .content-scroll {
	padding-top: 96px !important;
}	
	
.scrolling-section .fix-header-section .topfixed__bar {
	top: 96px !important;
	position:relative!important;
}	

/* .my-cv-header.sticky.shadow-blur, .my-cv-header.blur.shadow-blur {
	top: 10px !important;
} */

.my-cv-header.blur.shadow-blur {
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16)!important; 
}

.navbar {
	box-shadow: none!important;
}

.header-blur {
	 box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16)!important; 
	/* box-shadow: inset 0px 0px 2px #fefefed1!important; */
    -webkit-backdrop-filter: saturate(200%) blur(30px)!important;
    backdrop-filter: saturate(200%) blur(30px)!important;
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.scrolling-section .fix-header-section .fix-header-up {
	/* top: 77px !important; */
}

/* .fix-header-section {
	height: 100%;
}  */

body.disable-scroll {
  overflow: hidden; 
}

.cv-editor-options .form-group label {
	width: 100%;
	margin: 0 0 5px;
}

.cv-editor-options .input-group input.form-control {
	padding: 3px 3px 5px;
	transition: none;
}

.cv-editor-options .input-group.input-group-outline.is-filled .form-control, .cv-editor-options .input-group.input-group-outline.is-focused .form-control {
	border-color: #e91e63 !important;
	border-top-color: transparent !important;
	box-shadow: inset 1px 0 #e91e63,inset -1px 0 #e91e63,inset 0 -1px #e91e63;
	transition: none;
}

.cv-editor-options .input-group.input-group-outline::before {
	transition: none;
}

.cv-editor-options .input-group.input-group-outline.is-focused::before, .cv-editor-options .input-group.input-group-outline.is-filled::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	border-radius: 0.375rem;
	border: 2px solid #e91e63;
	transition: none;
}

.more-templates .btn {
	justify-content: space-between;
	display: flex;
	align-content: center;
	align-items: center;
}

.more-templates .btn .btn-inner--icon {
	position: relative;
	top: -1px;
}

.fonts-dropdown .custom-select {
	margin: 5px 0 0 !important;
	width: 100%;
}
.fonts-dropdown .custom-select .select-selected {
	margin: 0!important;
	width: 100%;
  background: none;
  border: 1px solid #d2d6da;
  border-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
  padding: 0.625rem 0.75rem !important;
  line-height: 1.3 !important;
}

/* .fonts-dropdown .custom-select .select-selected {
	padding: 0;
	border: none !important;
	background: no-repeat;
} */

.fonts-dropdown .custom-select .select-selected::after {
	top: 12px;
}

.fonts-dropdown .custom-select .select-selected.select-arrow-active::after {
	top: 11px;

}

.fonts-dropdown .custom-select .select-arrow-active::before {
	content: "";
	width: 100.6%;
	height: 104.7%;
	position: absolute;
	left: -1px;
	top: -1px;
	border-radius: 0.375rem;
	border: 2px solid #e91e63;
	transition: none;
}

.fonts-dropdown .custom-select .select-items::before {
    top: -18px;
}

.font-range-bar {
	width: 100%;
	margin:5px 0 0!important;
	display: flex;
	align-content: center;
	align-items: center;
}

.cv-editor-options .font-range-bar label, .cv-editor-options .font-range-bar button {
	width: 30px;
	display: flex;
	align-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
  background: transparent;
  border: none;
  color: #7b809a!important;
}

.cv-editor-options .font-range-bar label.text-sm, .cv-editor-options .font-range-bar button.text-sm {
	justify-content: flex-end;
}

.font-range-bar .slide-ranger {
	width: 100%;
	padding: 0 10px 0 0;
	cursor: pointer;
}

.theme-colors {
	position:relative;
}

.theme-colors .selected-color {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;
}

.theme-color-option {
	position: relative;
}

.theme-color-option .colors-list {
	position: absolute;
	background-color: #fbfbfb;
	width: 196px;
	padding: 10px;
	z-index: 1;
	top: 37px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
	border-radius: .25rem;
	display: flex;
	flex-flow: row wrap;
	right: 5px;
	top: 25px;
	display:none;
}

.theme-color-option .colors-list .color {
	border-radius: 25px;
	display: inline-block;
	padding: 0;
	width: 18px;
	height: 18px;
	margin: .11rem;
	cursor: pointer;
}

.theme-color-option .colors-list .color.selected-color {
	border: 2px solid #e9264d;
}

.cv-editor-options .toggle-switch label {
	margin: 0;
	width: auto;
}

.toggle-switch {
	margin: 5px 0 0;
}

.toggle-switch .form-switch {
	width: auto;
	padding: 0 15px 0 8px !important;
	margin: 0;
}

.toggle-switch .form-switch .form-check-input {
	margin: 0;
}

.font-range-bar .zoom-btn {
	display: flex;
	align-content: center;
}

.action__btns__new .dropstart.large-close-icon i.material-icons {
	font-size: 34px !important;
	margin: -6px 0 0;
	position: relative;
	top: -1px;
}

.topfixed__bar ul li .lighter {
	/* color: #7b809a !important; */
	color: #9a9eb0 !important;
}

.topfixed__bar ul li .lighter:hover {
	color: #344767 !important;
}

.animation-none, .animation-none.show {
	animation: none !important;
	transition: none !important;
}

.cv-edit-modal {
	z-index: 9999;
	padding: 15px 15px !important;
}

.modal-large .modal-dialog {
	/* max-width: 770px; */
	max-width: 820px;
	width: 100%;
	background: transparent;
	padding: 0;
}

.cv-edit-modal.show {
	opacity: 1;
	background: rgba(0, 0, 0, 0.4);
	visibility: visible;
}

.cv-edit-modal .input-group, .cv-edit-modal .input-group .input-group-text, .cv-edit-modal .input-group.input-group-dynamic .form-control, .cv-edit-modal .btn, .cv-edit-modal .custom-select .select-selected, .cv-edit-modal .input-group .form-label, .cv-edit-modal .avatar {
	transition: none !important;
}

.cv-edit-modal .form__btns .btn.btn.bg-gradient-light:hover {
	color: #fff;
	background-color: #191919;
	border-color: #191919;
	box-shadow: 0 14px 26px -12px rgba(52, 71, 103, 0.4), 0 4px 23px 0 rgba(52, 71, 103, 0.15), 0 8px 10px -5px rgba(52, 71, 103, 0.2);
	background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
}

.font-range-bar .noUi-base {
	height: 15px!important;
	top: -6px;
}

.font-range-bar .noUi-connects {
	top: 6px!important;
}

.font-range-bar .noUi-horizontal .noUi-origin {
	top: 6px;
}

.both-same-color .form-check-input {
	background-color: #42424a;
}

.page-number .form-check-input {
	background-color: #42424a;
}

.cv-editor-options .toggle-switch .form-check-input:active::after {
	box-shadow: none;
}

.page-number .form-check-input:checked {
	border-color: #ced4da;
	background-color: #ced4da;
}

.templates-side-pan {
	padding-right: 0;
	position: fixed;
	top: 0;
	width: 0;
	height: 100%;
	padding-left: 0;
	z-index: 9999;
	padding-bottom: 15px;
	padding-top: 15px;
	right:0;
	visibility: hidden;
}

.templates-side-pan.show {
	padding-right: 375px;
	width: 100%;
	padding-left: 17.425rem;
	visibility: visible;
}

.templates-wrapper {
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
	background-color: #fff; 
	width: 100%;
	height: 100%;
	border-radius: 0.75rem;
	padding: 20px 20px;
}

.templates-inner {
	height: 100%;
	position: relative;
}

.templates-close {
	position: absolute;
	right: -6px;
	top: -25px;
	margin: 0;
}

.template-heading {
	font-weight: 600;
	color: #344767;
}

.templates-scroll {
	height: calc(100% - 60px);
	overflow: auto;
}

.template-list {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 10px;
	padding: 0;
}

.template-list li {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	padding: 5px 7px;
	/* max-width: 160px; */
	max-width: 180px;
}

.template-list li img {
	max-width: 100%;
	border: 2px solid transparent;
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}

.template-list li img:hover {
	border: 2px solid #e91e63;
}

.template-list li label {
	font-size: 0.875rem;
	font-weight: 300;
	color: #7b809a;
	margin: .5rem 0 0;
}

.fixed-sidebar .card {
	position: fixed !important;
	right: -360px;
	top: 0;
	height: 100%;
	left: auto !important;
	transform: unset !important;
	width: 360px;
	border-radius: 0;
	padding: 0 10px;
	transition: .2s ease;
	z-index: 123456;
}


.fixed-sidebar.show .card {
	right: 0;
}

.nav__panel__right {
    margin-top: -3px;
}

.add-team-member-opener .avatar-group {
    display: flex;
    align-items: center;
}

.add-team-member-opener .avatar-group .avatar.add-team-member-opener {
    top: 0 !important;
}

.cv-edit-modal .level-options .select-items div:first-child {
	opacity: 1;
}

.textarea-editor {
	width: 100%;
	float: left;
	height: 100%;
}

.textarea-editor .ql-container {
	border-radius: 0 !important;
	border-width:0 !important;
}

.textarea-editor .ql-container .ql-editor {
	min-height: 200px;
	padding: 10px 0px;
	background: no-repeat bottom, 50% calc(100% - 1px);
	background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
	background-size: 0% 100%, 100% 100%;
	/* transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out; */
}

.min-height-130 .ql-container .ql-editor {
	min-height: 130px;
}

.textarea-editor .ql-container .ql-editor:focus, .textarea-editor .ql-container .ql-editor.is-filled {
	background-size: 100% 100%, 100% 100%;
	background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
}

.textarea-editor .ql-container .ql-editor p {
	color: #7b809a;
	font-size: 0.875rem;
  font-weight: 300;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.textarea-editor .ql-toolbar {
	border: none !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.textarea-editor .ql-snow .ql-picker {
	color: #7b809a;
	font-size: 0.875rem;
	font-weight: 400;
	font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.textarea-editor .ql-snow .ql-stroke {
	stroke: #7b809a;
}

.textarea-editor .ql-snow .ql-picker-label {
	padding-left: 0;
}

.textarea-editor .ql-snow.ql-toolbar .ql-picker-item:hover, .textarea-editor .ql-snow.ql-toolbar .ql-picker-label:hover, .textarea-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active, .textarea-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected { 
	color: #e91e63; 
}

.textarea-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke, .textarea-editor .ql-snow.ql-toolbar button:hover .ql-stroke, .textarea-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .textarea-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke { 
	stroke: #e91e63; 
}


.textarea-editor .ql-snow.ql-toolbar button:hover .ql-fill, .textarea-editor .ql-snow.ql-toolbar button.ql-active .ql-fill { 
	fill: #e91e63; 
}

.cv-editor-preview {
	    font-size: 0.875rem;
		line-height: 1.5;
		font-weight: 300;
}

.cv-editor-preview p {
	    font-size: 0.875rem;
		line-height: 1.5;
		font-weight: 300;
}  

.cv-editor-preview ul {
    margin: 0;
    padding: 0;
}

.cv-editor-preview ul li {
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 300;
    list-style: none;
    padding: 0 0 0 10px;
    position: relative;
}

.cv-editor-preview ul li:before {
    content: "";
    position: absolute;
    left: 0;
    width: 5px;
    height: 1px;
    background: #7b809a;
    top: 10px;
}

.cv-editor-preview ol {
    margin: 0;
    padding: 0 0 0 16px;
}

.cv-editor-preview ol li {
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 300;
    list-style: auto;
    padding: 0;
}

.cv-container {
    /* margin: 10px 0; */
    /* margin: 120px 0 50px 0; */
    margin: 120px 0 10px 0;
    /* overflow-y: auto; */
    overflow-x: auto;
    /* height: calc(100vh - 90px); */
    position: relative;
}

.editor-container {
    transition: transform .5s;
    /* padding-bottom: 50px; */
    flex-direction: column;
    transform-origin: center top;
}

.A4 {
    width: 794px;
    min-width: 794px;
    height: 1122px;
	min-height: 1122px;
}

.US {
    width: 816px;
    min-width: 816px;
    height: 1056px;
    min-height: 1056px;
}

.page {
	padding: 50px;
	margin-bottom: 1rem;
	background-color: #fff;
	position: relative;
}

.page-no {
	position: absolute;
	right: 30px;
	bottom: 15px;
}

.page-no {
	position: absolute;
	right: 30px;
	bottom: 15px;
	font-size: inherit;
	font-family: inherit;
	color: inherit;
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.page-container {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    position: relative;
}

/****************************************
	CV Editor color Variable Style Start 
****************************************/

:root {
  --color-1: rgb(223, 64, 83);
  --color-2: rgb(255, 72, 94);
  --color-3: rgb(255, 145, 96);
  --color-4: rgb(255, 191, 141);
  --color-5: rgb(255, 211, 176);
  --color-6: rgb(253, 220, 206);
  --color-7: rgb(255, 240, 233);
  --color-8: rgb(0, 0, 0);
  --color-9: rgb(49, 63, 76);
  --color-10: rgb(0, 129, 124);
  --color-11: rgb(0, 206, 198);
  --color-12: rgb(105, 194, 192);
  --color-13: rgb(139, 212, 210);
  --color-14: rgb(205, 234, 233);
  --color-15: rgb(240, 70, 37);
  --color-16: rgb(250, 200, 17);
  --color-17: rgb(254, 244, 69);
  --color-18: rgb(142, 208, 78);
  --color-19: rgb(218, 53, 218);
  --color-20: rgb(150, 15, 173);
  --color-21: rgb(66, 75, 178);
  --color-22: rgb(59, 89, 152);
  --color-23: rgb(0, 119, 181);
  --color-24: rgb(66, 133, 244);
  --color-25: rgb(116, 166, 248);
  --color-26: rgb(10, 184, 224);
  --color-27: rgb(183, 205, 240);
  --color-28: rgb(233, 239, 248);
  --color-29: rgb(0, 0, 0);
  --color-30: rgb(57, 57, 57);
  --color-31: rgb(109, 109, 109);
  --color-32: rgb(155, 155, 155);
  --color-33: rgb(182, 182, 182);
  --color-34: rgb(209, 209, 209);
  --color-35: rgb(255, 255, 255); 
  
  --font-family-1: Montserrat;
  --font-family-2: 'Source Sans Pro', sans-serif;
  --font-family-3: Open Sans;
  
  --font-size-9: 9px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;

  --cv-font-size: 12px;
  --cv-theme-color: rgb(223, 64, 83);
  --cv-font-family: Open Sans;
}

/****************************************
		CV Editor Colors Style Start 
****************************************/

.cv-theme-color {
	color:var(--cv-theme-color);
}
.color-1 {
	color:var(--color-1);
}
.color-2 {
	color:var(--color-2);
}
.color-3 {
	color:var(--color-3);
}
.color-4 {
	color:var(--color-4);
}
.color-5 {
	color:var(--color-5);
}
.color-6 {
	color:var(--color-6);
}
.color-7 {
	color:var(--color-7);
}
.color-8 {
	color:var(--color-8);
}
.color-9 {
	color:var(--color-9);
}
.color-10 {
	color:var(--color-10);
}
.color-11 {
	color:var(--color-11);
}
.color-12 {
	color:var(--color-12);
}
.color-13 {
	color:var(--color-13);
}
.color-14 {
	color:var(--color-14);
}
.color-15 {
	color:var(--color-15);
}
.color-16 {
	color:var(--color-16);
}
.color-17 {
	color:var(--color-17);
}
.color-18 {
	color:var(--color-18);
}
.color-19 {
	color:var(--color-19);
}
.color-20 {
	color:var(--color-20);
}
.color-21 {
	color:var(--color-21);
}
.color-22 {
	color:var(--color-22);
}
.color-23 {
	ccolor:var(--color-23);
}
.color-24 {
	color:var(--color-24);
}
.color-25 {
	color:var(--color-25);
}
.color-26 {
	color:var(--color-26);
}
.color-27 {
	color:var(--color-27);
}
.color-28 {
	color:var(--color-28);
}
.color-29 {
	color:var(--color-29);
}
.color-30 {
	color:var(--color-30);
}
.color-31 {
	color:var(--color-31);
}
.color-32 {
	color:var(--color-32);
}
.color-33 {
	color:var(--color-33);
}
.color-34 {
	color:var(--color-34);
}
.color-35 {
	color:var(--color-35);
}

/****************************************
		CV Editor Style Start 
****************************************/

.editor-container {
    font-family: var(--cv-font-family);
    font-size: var(--font-size-15);
	color: var(--bs-body-color);
}

/* .cv-section {
    padding-top: 0px;
    padding-bottom: 1rem;
} */

.contact {
    margin-top: 10px;
	padding-bottom: 1rem;
}
.contact p {
  /*  padding: 0.35rem .25rem; */
   padding: 0.25rem .25rem;
}

.contact p i {
	margin-right: 5px;
}

.name h1 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 0.8em;
	font-family: inherit;
	margin-bottom:0px;
	padding: .25rem;
	color: inherit;
}

.headine h5 {
	font-weight: 300;
	font-size: 18px;
	padding: .25rem;
	color: inherit;
}

.simple-text {
    position: relative;
}

.simple-text p {
    font-size: var(--cv-font-size);
	font-family: inherit;
	margin: 0;
	color: inherit;
	position: relative;
}

.simple-text p strong {
	color: #344767;
}

.section-paragraph.hover, .section-paragraph {
	padding: .25rem;
}

.contact p {
	line-height: 1em ;
	color: inherit;
}

.contact .simple-text {
    margin-top: -3px;
    margin-bottom: -3px;
}

.editor-border-left {
	border-left: 10px solid;
	margin-left: 0.25rem;
	margin-bottom: 0.9rem;
	height: 36px;
}

.cv-detail-section {
	/* padding-bottom: 1.8rem; */
	margin-bottom: 1rem;
	padding-top: 0;
	position: relative;
}

.section-header h4 {
	margin: 0;
	font-weight: 800;
	font-size: 24px;
	padding-left: .25rem;
	padding-right: .25rem;
}

.simple-text ul {
	margin: 0;
	padding: 0;
}

.simple-text ul li {
	margin: 0;
	padding: 0 0 0 11px;
	font-size: var(--cv-font-size);
	font-family: inherit;
	color: inherit;
	font-weight: 300;
	list-style: none;
	position: relative;
}

.simple-text ul li::before {
	position: absolute;
	left: 2px;
	content: "";
	width: 4px;
	height: 1px;
	background: var(--bs-body-color);
	top: 12px;
}

.page-container .hover:hover, .page-container .block-hover {
	background: var(--bs-gray-200); 
}

.edit-btns {
	position: absolute;
	left: -20px;
	width: 25px;
	top: 0px;
	visibility: hidden;
}

.edit-btns .btn {
	padding: 0;
	margin: 0;
}

.edit-btns .btn:hover i {
	color: #e91e63!important;
}
.simple-text:hover .btn  {
	visibility: visible;
}

.side__main__nav {
	background: #191919;
}

 /****************************************
		CV Editor Style End 
****************************************/

/*****************************
		Responsive Start
*****************************/

/* @media screen and (min-device-width: 993px) and (max-device-width: 1400px) { 

.left__nav__search {
  max-width: 75%;
  width: 75%;
}

.navselect__new {
	padding-right: 0;
	max-width: 25%;
	margin: 0 !important;
}
   
} */

@media screen and (min-width: 767px) and (max-width: 1600px) { 

.left__nav__search {
  max-width: 75%;
  width: 75%;
}

.navselect__new {
	padding-right: 0;
	max-width: 25%;
	margin: 0 !important;
}

   
}


@media(min-width:1200px){
	
/* .question__block ul li {
	z-index: 9999 !important;
} */

/* .fix-header-section {
	height: 100vh;
	margin: 0 !important;
} */

/*  .sidenav.fixed-start + .main-content {
	height: 100%;
} 

.fix-header-section {
	height: 100%;
}  */ 
	
.content-scroll {
        height: calc(100vh - (height_of_fixed_topbar_outer));
    }	
	
} 	
	
@media(max-width:1600px){

.header__bottom__options .navbar__search__left {
	width:55%;
}

/* .full--width--modal .modal-dialog {
    padding: 0 25px 0 18px;
} */


.view__details__area .card-detail-head  {
	width: 60%;
	  padding-right: 15px;
}

.view__details__area .card-detail-head h6 {
    width: 100%!important;
    word-break: break-all;
}

}

@media(max-width:1400px){

.header__bottom__options .navbar__search__left {
	width:60%;
}

.new__header__options .filter-search-inner {
	min-width: 20%;
	width: 20%;
}

.view__details__area .card .card-body .card-detail-head.member-card-info h6 {
    font-size: 16px!important;
}

.nav__panel__right li {
    margin: 0 8px;
}

.my-cv-header .language__dropdown {
    width: 160px;
}


}

@media(max-width:1300px){

.header__bottom__options .navbar__search__left {
	width:65%;
}

/* .my-cv-header .header__top__link__area {
    flex-wrap: wrap;
}

.my-cv-header .nav__panel__right {
    padding-left: 25px;
    order: 1;
    width: 100%;
} */


}

@media(max-width:1299px){

/* .templates-side-pan.show {
	padding-left: 7.825rem;
} */

}
	
@media(max-width:1200px){	

.my-cv-header {
	width: calc(100% - 3.025rem);
}

/* .full--width--modal .navbar-main {
	padding-left: 20px !important;
} */

.full--width--modal .modal-dialog {
    padding: 0 23px 0 25px;
}

.full--width--modal .right__nav__bar.navbar-expand-lg .action__btns__new .navbar-collapse {
    right: 11px;
}

.full--width--modal .container-fluid.px-md-4 {
    padding-right: 1rem!important;
}

.full--width--modal, .full--width--modal.widthless {
	width: 100%;
	z-index: 1234;
}

.g-sidenav-pinned .full--width--modal, .g-sidenav-hidden .full--width--modal {
	width: 100%;
}

.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar, .g-sidenav-pinned .full--width--modal .navbar.right__nav__bar, .g-sidenav-show .full--width--modal .navbar.right__nav__bar {
    /* width: calc(100% - 4.225rem)!important; */
    width: 100%!important;
} 

.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar, .g-sidenav-pinned .full--width--modal .navbar.right__nav__bar, .g-sidenav-show .full--width--modal .navbar.right__nav__bar {
   /*  width: 96%!important; */
	width: 100%!important;
}

.g-sidenav-hidden .full--width--modal nav.navbar.navbar-main.navbar-expand-lg.position-fixed.right__nav__bar.nav__with__slider__option.new__header__bar.blur.shadow-blur {
    /* width: calc(100% - 3.025rem)!important; */
	width: 100%!important;
    right: 0;
} 





#navbarBlur.navbar.navbar-main .container-fluid {
	padding-right: 53px !important;
}

#navbarBlur.navbar.navbar-main.new__header__bar .container-fluid {
	padding-right: 1rem !important;
}

.right__nav__bar.navbar-expand-lg .navbar-collapse {
	position: absolute;
	right: 17px;
}

.cross__icon {
	position: relative;
	right: 0;
	top: 3px;
}

.right__nav__bar.navbar-expand-lg .navbar-collapse {
	justify-content: flex-end;
}

.nav__panel__right {
	padding-left: 0;
}

.nav__panel__right li:first-child {
	margin-left: 0;
}

.scroll__bar__height {
	max-height: 60vh;
}

.action__btns__new {
	position: relative;
	padding-right: 35px;
}

.right__nav__bar.navbar-expand-lg .action__btns__new .navbar-collapse {
	right: 9px;
	top: 11px;
}

#sidenav-main.sidenav.navbar {
	transform:translateX(-17.125rem);
	-webkit-transform:translateX(-17.125rem); 
	-moz-transform:translateX(-17.125rem); 
	-ms-transform: translateX(-17.125rem); 
	-o-transform: translateX(-17.125rem);
}

.g-sidenav-pinned #sidenav-main.sidenav.navbar {
	transform:translateX(0);
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform: translateX(0); 
	-o-transform: translateX(0);
}

.none--tablet {
	display: none;
}

.right--padding--none--tablet {
	padding-right: 0;
}

.tablet--margin--none {
	margin: 0;
	padding: 0;
}

.right--padding--none--mobile, .tablet--pl-0 {
	padding-left: 0;
}

.my-cv-header .nav__panel__right {
    padding-left: 25px;
} 

.g-sidenav-hidden .my-cv-header {
    width: calc(100% - 3rem);
}

.my-cv-header .language__dropdown {
    margin-left: 0 !important;
}

.templates-side-pan.show {
	padding-left: 0.825rem;
}

}

	
@media(max-width:992px){	

/* .full--width--modal #navbarBlur.navbar.navbar-main .container-fluid {
	padding-right: 85px !important;
} */

.bottom__mobile {
	order: 2;
	position: relative !important;
	width: 100%;
	min-height: 350px;
	padding: 0;
}

.min__height {
	min-height: 350px;
}

.language__select__option .dropdown:not(.dropdown-hover) .dropdown-menu {
	margin-top: 35px !important;
}

.nav.nav-pills.tab--view--select .moving-tab.nav-link {
	display: none;
}

.new__header__options .nav__search__input {
	min-width: 220px;
	max-width: 250px;
}

.new__header__options .filter-search-inner {
	min-width: 140px;
	width: 100%;
}

.filter-search-inner {
	min-width: 120px;
}

.choices__list--dropdown .choices__item {
	padding: 0.3rem 0.6rem;
	font-size: 0.775rem;
}

.navpanel__select__end__action .dropstart {
	top: 34%;
}

.right__nav__bar.navbar-expand-lg .action__btns__new .navbar-collapse {
	top: 6px;
}

.card__right__container {
	margin-top: 15px !important;
}


.dropdown-menu.show {
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.lisiting__table__new .table-responsive {
       overflow-x: auto;
    overflow-y: hidden;
}

.header__bottom__options {
	flex-wrap: wrap;
	justify-content: flex-end !important;
}

/* .filter-search-inner {
	min-width: 160px;
} */

.left__nav__search {
	max-width: 100%;
	width: 100%;
	padding-right: 8px !important;
}

.navselect__new {
	max-width: none;
	width: auto;
	float: right;
	min-width: 300px;
	margin-top: 10px !important;
}

.slider__panels .nav.nav-pills.nav-fill {
	flex-direction: row !important;
}

.language__dropdown {
	top: 0;
	min-width: 160px !important;
	max-width: 76%;
	margin-left: 0 !important;
}

.fix-header-section .content-scroll {
    margin-top: 0 !important;
}

.my-cv-header .header__top__link__area {
    padding-bottom: 50px;
}

.my-cv-header .language__dropdown {
    margin-left: 0 !important;
    position: absolute;
    top: auto;
    left: 18px;
    width: 180px;
    bottom: 15px;
}

.my-cv-header .nav__panel__right {
    padding-left: 0;
}

.my-cv-header .progress__bar {
    position: absolute;
    bottom: 20px;
    right: 18px;
}

.scrolling-section .fix-header-section .topfixed__bar {
    top: 135px !important;
}

.fix-header-section .content-scroll {
   padding-top: 135px !important;
}

.fix-header-section .content-scroll {
    padding-top: 25px !important;
}

.content-scroll {
    height: auto;
}

.editor_menu .navpanel__select__end__action .dropstart {
	top: 7px;
}

.right__nav__bar.navbar-expand-lg.editor_menu .action__btns__new .navbar-collapse {
	top: 11px;
}

.cv-container {
	margin: 120px 0 0px 0;
}

.editor-container {
	padding-bottom: 0;
	overflow: scroll !important;
	height: calc(100vh - 80px);
	width: 100%;
	align-content: flex-start !important;
	align-items: flex-start !important;
}

.header-without-dropdown .header__top__link__area {
	padding-bottom: 0;
}


#navbarBlur.navbar.navbar-main.header-without-dropdown .container-fluid {
	padding-right: 0px !important;
}

.header-without-dropdown .navpanel__select__end__action .dropstart {
	top: 5px;
}

.right__nav__bar.navbar-expand-lg .action__btns__new .navbar-collapse {
	top: 10px!important;
}

}


@media(max-width:767px){

.full--width--modal .container-fluid.px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.full--width--modal .navbar.right__nav__bar {
    right: auto;
    left: auto;
    margin-left: auto!important;
    margin-right: auto!important;
}

.g-sidenav-hidden .full--width--modal .navbar.right__nav__bar, .g-sidenav-pinned .full--width--modal .navbar.right__nav__bar, .g-sidenav-show .full--width--modal .navbar.right__nav__bar {
    width: 100%!important;
}

/* .g-sidenav-hidden .full--width--modal nav.navbar.navbar-main.navbar-expand-lg.position-fixed.right__nav__bar.nav__with__slider__option.new__header__bar.blur.shadow-blur {
    width: calc(100% - 0.525rem)!important;
} */

.full--width--modal .nav__with__slider__option .navpanle__slider__select.action__btns__new {
    display: flex;
    align-items: start;
    align-content: start;
    justify-content: flex-end;
}

#navbarBlur.navbar.navbar-main .container-fluid {
	padding-right: 1rem !important;
}

.nav__with__slider__option .left__menu__bar {
	max-width: 100%;
	width: 100%;
	flex-wrap: wrap;
	padding-right: 0;
}

.nav__with__slider__option .navbar__search__left {
	position: relative;
	top: 0;
	padding-left: 0;
	width: 100%;
	order: 1;
	margin: 0 0 20px;
}


.nav__with__slider__option .nav__panel__right {
	padding-left: 0;
	order: 1;
	margin-top: 10px;
	margin-bottom: 5px;
}

.nav__with__slider__option .collapse.navbar-collapse {
	margin: 0 !important;
	width: 30px;
	position: absolute;
	right: 15px;
	top: 26px;
}

.nav__with__slider__option .navpanle__slider__select {
	width: 100%;
	max-width: 100%;
}


.left__nav__search {
  max-width: 100%;
  width: 100%;
}

.nav.nav-pills.tab--view--select, .fullwidth__stack {
	flex-direction: column !important;
	margin-top: 20px;
	/* max-width: 96.6%;
	margin: 20px auto 0; */
}

.fullwidth__stack .moving-tab {
	display: none;
}

.fullwidth__stack .nav-link.active {
	background: #fff;
	box-shadow: 0px 1px 5px 1px #ddd;
}

.scroll__bar__height {
	max-height: 60vh;
}

.mobile-flex {
	flex-wrap: wrap;
	width: 100%;
}

.filter-search-inner {
	width: 100%;
}

.dropstart.showin-mobile {
	margin: 0 0 0 8px;
	top: 21px;
	position: absolute;
	right: 46px;
	display: block;
}

.dropstart.showin-mobile .material-icons {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.dropstart.showin-mobile .dropdown-menu[data-bs-popper] {
	margin-top: 23px;
	margin-right: -17px;
}

.disable__mobile {
	display: none;
}

.navpanel__select__end__action {
	padding-right: 0;
}

.choices .choices__list.choices__list--dropdown {
	z-index: 12;
}

.header__top__link__area {
    justify-content: space-between!important;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    padding-right: 100px;
}

.header__bottom__options {
	display: inline-block !important; 
	width: 100%;
}

.header__top__link__area .left__menu__bar {
	width: auto;
	margin: 0 !important;
}

.nav__with__slider__option .navpanle__slider__select.action__btns__new {
    position: absolute;
    padding-right: 35px;
    width: auto;
    min-width: 100px;
    right: 0;
}

.navpanel__select__end__action.action__btns__new .dropstart {
	top: 4px;
}

.right__nav__bar.navbar-expand-lg .action__btns__new .navbar-collapse {
	top: 9px;
}

.filter-search-inner.custom-select, .filter-search-inner.custom--select--search, .input__filter__tags, .bulkaction__tab {
	padding: 0.5rem 0 7.5px;
}

.custom-select .select-items, .custom--select--search .open .list {
	top: 48px;
}

.custom-select .select-items.select-hide {
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
}

.custom-select .select-items {
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1);
}

.cross__icon .nav-link .material-icons {
	font-size: 23px !important;
}

/* .cross__icon {
	padding-right: 0 !important;
} */


/* .right__nav__bar.navbar-expand-lg .navbar-collapse {
	margin-top: 0 !important;
	padding-right: 25px;
} */

.right__nav__bar.navbar-expand-lg .navbar-collapse .ps__rail-y {
	display: none !important;
}

.input__filter__tags .filters--listing {
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
}

.right__nav__bar.nav__with__slider__option .navbar-collapse {
	margin-top: 0 !important;
	padding-right: 0;
}

.nav-tabs .nav-link {
	text-indent: -500px;
	overflow: hidden;
	font-size: 0;
}

.navpanel__select__end__action .dropstart i.material-icons {
	font-size: 24px !important;
	top: 0px;
}


.dropstart.showin-mobile i.material-icons {
	font-size: 24px !important;
}

.cross__icon {
	padding: 0 5px !important;
}

.left__nav__search {
	max-width: 100%;
	width: 100%;
	padding-right:0px !important;
}

.nav__search__input {
	min-width: 100%;
	max-width: 100%;
}

.new__header__options .nav__search__input {
	min-width: 100%;
	max-width: 100%;
}

.slider__panels .nav.nav-pills.nav-fill {
	flex-direction:column!important;
}

.navselect__new {
	max-width: 100%;
	min-width: 100%;
}

.none---mobile {
	display: none;
}

.right--padding--none--mobile, .right--padding--none--tablet {
	padding-left: 0;
	padding-right: 0;
}


.modal__btns {
	flex-wrap: nowrap;
}

.modal__btns .col-md-6 {
	width: 50%;
}

.reset__password__form {
	padding: 0;
}

.step__choose label {
	margin-left:auto!important;
	margin-right:auto!important;
}

.nav.nav-tabs.mutiselect__tabs.before__none .nav-link {
	text-indent: unset;
}

.question__list__inner__block {
	padding-right: 75px;
}

.question__list__inner__block .custom-select .select-selected {
	padding-right: 30px;
}

.question__list__inner__block .custom-select .select-selected::after {
	right: 2px;
}

.language__dropdown {
	margin-bottom: 8px;
}

.detail__img {
	order: 1;
}

.personal--detail__block {
	order: 2;
	margin-top: 20px;
}

.level-text {
	padding-right: 0;
}

.skills-listing {
	padding-right: 98px;
}

.my-cv-header .language__dropdown {
    margin: 0 !important;
    left: 2px;
    bottom: 0px;
}

.my-cv-header .progress__bar {
    position: absolute;
    bottom: 5px;
    right: 2px;
}

.my-cv-header.right__nav__bar.navbar-expand-lg .navbar-collapse {
    right: 0;
    margin-right: 0!important;
}

.my-cv-header .header__top__link__area {
    padding-bottom: 40px;
}

.nav__panel__right li a {
    font-size: 14px;
}

.right__nav__bar.navbar-expand-lg.editor_menu .action__btns__new .navbar-collapse {
	top: 11px;
	right: 0;
	margin: 0 !important;
}

.editor_menu .action__btns__new .dropstart.large-close-icon i.material-icons {
	top: 5px;
}

.editor_menu .navpanel__select__end__action.action__btns__new .dropstart {
	top: 1px;
}

.editor_menu .navpanel__select__end__action {
	padding-right: 23px;
	position: absolute;
	right: 0;
}

.templates-side-pan.show {
	width: 100%;
	z-index: 999999;
	top: 0;
	padding: 6px;
}

#navbarBlur.navbar.navbar-main.header-without-dropdown .container-fluid {
	padding-right: 1rem !important;
}

.header-without-dropdown .header__top__link__area {
	padding-bottom: 0;
	padding-right: 0px;
}

.header-without-dropdown .navpanel__select__end__action .dropstart {
	top: 0;
}

.header-without-dropdown .navpanel__select__end__action .dropstart.large-close-icon {
	top: 5px;
}

.header-without-dropdown .navpanel__select__end__action {
	padding-right: 25px;
}

}	
	
@media(max-width:680px){
	
.scrolling-section .fix-header-section .topfixed__bar {
    top: auto!important;
    margin-top: 150px;
}	


	
}	
	
	
@media(max-width:600px){

.my-cv-header {
	width: calc(100% - 0.925rem);
}

.full--width--modal .modal-dialog {
    padding: 0 8px 0 8px;
}

.full--width--modal .container-fluid.px-md-4 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

.right__nav__bar  {
	margin-right: 0.4rem !important;
	margin-left: 0.5rem !important;
}
	
.nav__panel__right li {
	margin: 0 10px;
}

.nav__panel__right li:first-child {
	margin-left: 0;
}	

.nav__panel__right li:last-child {
	margin-right: 0;
}

.month__year__option .col-sm-6 {
	max-width: 50%;
}

.new__modals .password__modal__block {
	width: 95%;
}

.g-sidenav-hidden .my-cv-header {
    width: calc(100% - 0.9rem);
}
	
.my-cv-header .header__top__link__area {
    padding-right: 45px;
}

.my-cv-header .cross__icon {
    padding: 0 0px !important;
	right: -8px;
        top: 4px;
}

.my-cv-header .nav__panel__right li {
    margin: 0 5px;
}

.my-cv-header .nav__panel__right li:first-child {
    margin-left: 0;
}

.my-cv-header .language__dropdown {
    width: 160px;
}

.header-without-dropdown .header__top__link__area {
	padding-right: 0px;
}
	
}


@media(max-width:575px){

.ml-15 {
	margin-left: 5px !important;
}

.mr-15 {
	margin-right: 5px !important;
}

.form__btns .col-sm-6 {
	width: 50%;
}

.form__btns .btn {
	padding: 0.625rem 0.8rem;
	letter-spacing: 0;
}

.right__nav__bar.navbar-expand-lg .navbar-collapse {
	margin-top: 0 !important;
}

.sort__icons {
	top: 20px;
}

.sort__icons .material-icons {
	font-size: 1.325rem !important;
}

.question__block ul li textarea {
	padding-right: 90px;
}
	
}	
