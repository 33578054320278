.navbar-vertical .navbar-nav > .nav-item .nav-link.active {
  background-color: transparent !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background: url("/assets/img/close-icon.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.navbar-vertical .navbar-nav > .nav-item .nav-link.collapse-link.active {
  background-color: hsla(0, 0%, 78%, 0.2) !important;
  background-image: none;
}

.navbar-vertical .navbar-nav .nav-link {
  margin-top: 1.5px !important;
  margin-bottom: 1.5px !important;
}

.card-view-candidate-name:hover {
  color: #de4565;
}

.text-highlight {
  color: #de4565;
}

.sidenav {
  z-index: 9999999 !important;
}

.fixed-plugin .card {
  z-index: 9999999 !important;
}

.swal2-backdrop-show {
  z-index: 9999999999 !important;
}

.nav--tabs .nav-item a.active {
  box-shadow: 0 1px 5px 1px #ddd;
}

.swal2-popup {
  border-radius: 10px !important;
}

.swal2-styled.swal2-confirm {
  background-image: linear-gradient(
    195deg,
    #49a3f1 0%,
    #1a73e8 100%
  ) !important;
  background-color: transparent !important;
  padding: 0.625rem 1.5rem !important;
  font-size: 0.75rem !important;
  border-radius: 0.5rem !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.btn.bg-gradient-info:hover {
  background-color: #1a73e8 !important;
  border-color: #1a73e8;
  box-shadow: 0 14px 26px -12px rgb(26 115 232 / 40%),
    0 4px 23px 0 rgb(26 115 232 / 15%), 0 8px 10px -5px rgb(26 115 232 / 20%) !important;
}

.action__btns__new .dropstart.large-close-icon i.material-icons {
  font-size: 34px !important;
  margin: -6px 0 0;
  position: relative;
  top: -1px;
}

.choices__item {
  word-break: break-word;
}

.filters--listing li.is-highlighted {
  background: #f0f2f5;
  color: #344767;
}

.filters--listing li {
  opacity: 1;
}

.is-filled .form-label {
  color: #344666;
}

.notes__input textarea {
  resize: none !important;
  height: 100% !important;
}

.custom-select .is-highlighted {
  background: #f0f2f5;
  color: #344767;
}

.custom-select select,
.custom--select--search select {
  display: block;
  height: 0px;
  padding: 0px;
  border: none;
  outline: none;
  position: absolute;
  left: -99999px;
}

.btn-light:focus,
.btn.bg-gradient-light:focus {
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  box-shadow: 0 14px 26px -12px rgb(240 242 245 / 40%),
    0 4px 23px 0 rgb(240 242 245 / 15%), 0 8px 10px -5px rgb(240 242 245 / 20%);
}
.bg-gradient-light:focus {
  box-shadow: 0 14px 26px -12px rgba(52, 71, 103, 0.4),
    0 4px 23px 0 rgba(52, 71, 103, 0.15), 0 8px 10px -5px rgba(52, 71, 103, 0.2) !important;
}

.btn-dark:focus,
.btn.bg-gradient-dark:focus {
  background-color: #344767;
  border-color: #344767;
  box-shadow: 0 14px 26px -12px rgb(52 71 103 / 40%),
    0 4px 23px 0 rgb(52 71 103 / 15%), 0 8px 10px -5px rgb(52 71 103 / 20%);
}

.topfixed__bar.sticky {
  top: 74px !important;
}

.checkbox__grey.form-check-input[type="checkbox"]:checked {
  background: #c5c7cd !important;
}

.checkbox__grey.form-check-input[type="checkbox"]:checked,
.checkbox__grey.form-check-input[type="radio"]:checked {
  border-color: #c5c7cd !important;
}

.checkbox__grey.form-check-input[type="checkbox"]::after {
  color: #fff !important;
}

.bulkaction__tab .select-selected {
  color: #e91e62;
}

.side__main__nav .navbar-nav .material-symbols-outlined {
  font-size: 24px;
  margin-left: 4px;
  display: flex;
  max-width: 26px;
  min-width: 26px;
}

.form-check {
  margin-bottom: 0px;
}

.swal2-checkbox #swal2-checkbox,
.swal2-checkbox .swal2-label {
  cursor: pointer;
}

.right-close-icon .material-icons:hover {
  color: #e91e63 !important;
}

.hover-color-effect:hover {
  color: #e91e63 !important;
}

.flatpickr-calendar {
  z-index: 9999999 !important;
}
.flatpickr-current-month .numInputWrapper {
  opacity: 0 !important;
}
.flatpickr-year-select {
  z-index: 100;
  position: absolute;
  top: 10px;
  right: 40px;
  width: 95px;
}
.flatpickr-year-select select {
  width: 100%;
  height: 24px;
  font-size: 14px;
  outline: 0;
  overflow: hidden;
  background: #ffffff;
  border: #ffffff;
  padding: 0;
  cursor: pointer;
  font-weight: 300;
  color: #000 !important;
}
.flatpickr-year-select select:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month {
  left: 4.5% !important;
}
.flatpickr-monthDropdown-months {
  font-size: 14px !important;
}
.flatpickr-calendar .flatpickr-day.today {
  background: #191919 !important;
}
.flatpickr-calendar .flatpickr-day.today.selected {
  background: #e91e63 !important;
}
.flatpickr-wrapper {
  width: 100%;
}
.flatpickr-calendar {
  left: 3px;
}
.fixed-plugin .card {
  width: 380px !important;
  right: -380px;
}
.z-index-top {
  z-index: 9999999 !important;
}
.mb-330 {
  margin-bottom: 330px;
}
.white-placeholder::placeholder {
  color: white;
  opacity: 1;
  font-weight: 600;
}
.white-placeholder {
  font-size: var(--bs-body-font-size);
  font-weight: 600;
}
.ql-container {
  min-height: 216px;
  border: none !important;
}
.ql-toolbar {
  border: none !important;
  padding-left: 0px !important;
  margin-left: -9px !important;
}
.ql-editor {
  padding-left: 0px !important;
}
.ql-editor ul[data-checked="true"] > li::before,
.ql-editor ul[data-checked="false"] > li::before {
  font-size: 24px;
  position: relative;
  top: 2px;
}
.ql-editor ul[data-checked="true"] > li,
.ql-editor ul[data-checked="false"] > li {
  cursor: pointer !important;
}
.ql-editor.ql-blank::before {
  font-style: normal !important;
  line-height: 20px !important;
  font-size: 0.875rem !important;
  color: #a5aabf !important;
  font-weight: 400 !important;
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  left: 0px !important;
}
.ql-editor,
.ql-editor p {
  line-height: 20px !important;
  font-size: 0.875rem !important;
  color: #7b809a !important;
  font-weight: 400 !important;
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
}
.question__list__inner__block {
  margin-bottom: 10px !important;
}
.question__block ul li textarea {
  padding-right: 100px !important;
}
.sort__icons {
  top: 14px !important;
}
.questionnaire-textarea {
  transition: none !important;
  padding-top: 7px !important;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto !important;
  }
}
.white-color-hover:hover i {
  color: #dddddd !important;
}
.choices__list--dropdown {
  z-index: 9999 !important;
}
.actn__btn__in .material-symbols-outlined:hover {
  color: #e91e63 !important;
}
@media (min-width: 992px) {
  .left-align-dropdown.dropdown:not(.dropdown-hover) .dropdown-menu {
    margin-top: 2px !important;
    right: 20px !important;
  }
}
.left-align-dropdown.dropdown .dropdown-menu.dropdown-menu-end:before {
  display: none !important;
}

.mb-2-important {
  margin-bottom: 0.5rem !important;
}

.signup-checkbox {
  top: 2px;
  border-radius: 2px !important;
  width: 15px;
  height: 15px;
  margin-right: 6px;
}

.signup-checkbox:checked {
  background-color: #344767 !important;
  border-color: #344767 !important;
}

.signup-button:disabled {
  background-color: #646464 !important;
}

.top-bar-candidate-name {
  width: 100%;
  text-align: center;
  padding-left: 260px;
  color: #7b809a;
}

@media screen and (max-width: 425px) {
  .top-bar-candidate-name {
    padding-left: 100px;
  }
}

@media screen and (max-width: 768px) {
  .top-bar-candidate-name {
    padding-left: 140px;
  }
}

.passsword__input_signup .input-group.input-group-outline.focused.is-focused,
.passsword__input_signup
  .passsword__input_dark
  .input-group.input-group-outline.is-filled,
.passsword__input_signup .input-group.input-group-outline.is-filled {
  border-color: #323237 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #323237, inset -1px 0 #323237, inset 0 -1px #323237;
}

.cursor-unset {
  cursor: unset !important;
}

.company__cards .card.active,
.company__cards .card.active .card-header {
  background-color: #dddee1;
}

.company__cards .card:hover {
  background-color: #dddee1 !important;
}

textarea.form-control:disabled {
  padding: 0 !important;
  background-color: transparent !important;
}

.side__main__nav .navbar-nav .material-icons-outlined {
  font-size: 24px;
  margin-left: 4px;
  display: flex;
  max-width: 26px;
  min-width: 26px;
}

.view__details__area .card {
  min-height: unset !important;
}

.bg--gray {
  background-color: #7b809a;
}

.disabled-checkbox-overlay {
  width: 34px;
  height: 20px;
  position: absolute;
  margin-right: 40px;
}

.notes__input .form-control {
  -webkit-text-fill-color: #7b809a;
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/password.ttf);
}

input.key {
  font-family: "password";
}

.personal--edit--form .input-group.is-filled label.form-label {
  color: #bbb9b9 !important;
}

.multisteps-form__content textarea.form-control {
  padding: 0.6rem 0 !important;
}

.disabled-link .text-secondary {
  cursor: not-allowed !important;
  color: #b3b6c4 !important;
}

.disabled-link .text-secondary:hover {
  cursor: not-allowed !important;
  color: #b3b6c4 !important;
}

.calender-box {
  z-index: 99;
}

.invalid-feedback {
  border-radius: 0.375rem !important;
}

.active-filter-btn {
  border: 1px solid transparent !important;
  color: #e91e63;
}
.active-filter-btn::before {
  opacity: 1 !important;
}

.notepad-quill-editor .ql-toolbar.ql-snow {
  border: 1px solid #ccc !important;
  border-top-right-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
  margin-left: 0px !important;
}

.notepad-quill-editor .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid #ccc !important;
  border-top: 0px !important;
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.notepad-quill-editor .ql-container.ql-snow.ql-disabled {
  border: 1px solid #ccc !important;
  border-radius: 0.375rem !important;
}

.notepad-quill-editor .ql-container {
  padding: 12px 15px !important;
  min-height: unset !important;
  padding-bottom: 40px !important;
}

.notepad-quill-editor .ql-editor.ql-blank::before {
  padding-left: 15px !important;
}

.notepad-quill-editor .ql-editor {
  padding: 0px !important;
}

.note-discription p {
  margin-bottom: 0px;
  line-height: 1.5 !important;
  font-size: 0.875rem !important;
}

.notepad-quill-view .ql-editor {
  padding: 0px !important;
}

.notepad-quill-view .ql-container {
  min-height: unset !important;
}

.hover-bold:hover {
  font-weight: bold;
}

.p-mb-0 p {
  margin-bottom: 0px !important;
}

.noUi-origin {
  width: 100% !important;
}
