@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Black.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Light.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Black.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Light.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/roboto/Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}




@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-Black.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-Bold.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-ExtraBold.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-ExtraLight.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-Light.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-Medium.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-SemiBold.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-Regular.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/roboto/RobotoSlab-Thin.woff2') format('woff2'),
        url('../fonts/roboto/RobotoSlab-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

